<template>
    <svg v-if="isActive" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1958_8787)">
            <circle cx="12.5" cy="12.5" r="12.5" fill="#0F243F"/>
            <path d="M6.35001 13.6283L13.6033 6.375M13.6033 6.375H9.71M13.6033 6.375V10.2683" stroke="white"
                  stroke-width="0.948387" stroke-linecap="round"/>
            <path d="M18.6 11.3717L11.3467 18.625M11.3467 18.625H15.24M11.3467 18.625V14.7317" stroke="white"
                  stroke-width="0.948387" stroke-linecap="round"/>
        </g>
        <defs>
            <clipPath id="clip0_1958_8787">
                <rect width="25" height="25" fill="white"/>
            </clipPath>
        </defs>
    </svg>
    <svg v-else width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1958_8795)">
            <circle cx="12.5" cy="12.5" r="12.5" fill="white"/>
            <path d="M6.35001 13.6283L13.6033 6.375M13.6033 6.375H9.71M13.6033 6.375V10.2683" stroke="#0F243F"
                  stroke-width="0.948387" stroke-linecap="round"/>
            <path d="M18.6 11.3717L11.3467 18.625M11.3467 18.625H15.24M11.3467 18.625V14.7317" stroke="#0F243F"
                  stroke-width="0.948387" stroke-linecap="round"/>
        </g>
        <defs>
            <clipPath id="clip0_1958_8795">
                <rect width="25" height="25" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    props: {
        isActive: Boolean
    },
    name: "TransactionsIcon"
}
</script>

<style>

</style>
