<template>
    <svg v-if="isActive" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.4711 3.65782C17.5344 4.29671 20.7544 7.49116 21.3678 11.5545H19.4367H13.4711V5.56682V3.65782Z"
              fill="#0F243F"/>
        <path
                d="M1.5 12.5C1.5 6.41353 6.43764 1.5 12.5 1.5C18.5624 1.5 23.5 6.41353 23.5 12.5C23.5 18.5865 18.5624 23.5 12.5 23.5C6.43764 23.5 1.5 18.5865 1.5 12.5Z"
                stroke="#0F243F"/>
        <path
                d="M12 12.5V13H12.5H18.9811C18.7257 16.3562 15.9216 19 12.5 19C8.91015 19 6 16.0899 6 12.5C6 9.07839 8.64378 6.27426 12 6.01894V12.5Z"
                fill="#0F243F" stroke="#0F243F"/>
    </svg>
    <svg v-else width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.4711 3.65782C17.5344 4.29671 20.7544 7.49116 21.3678 11.5545H19.4367H13.4711V5.56682V3.65782Z"
              fill="white"/>
        <path
                d="M1.5 12.5C1.5 6.41353 6.43764 1.5 12.5 1.5C18.5624 1.5 23.5 6.41353 23.5 12.5C23.5 18.5865 18.5624 23.5 12.5 23.5C6.43764 23.5 1.5 18.5865 1.5 12.5Z"
                stroke="white"/>
        <path
                d="M12 12.5V13H12.5H18.9811C18.7257 16.3562 15.9216 19 12.5 19C8.91015 19 6 16.0899 6 12.5C6 9.07839 8.64378 6.27426 12 6.01894V12.5Z"
                fill="white" stroke="white"/>
    </svg>
</template>

<script>
export default {
    props: {
        isActive: Boolean
    },
    name: "StatisticsIcon"
}
</script>

<style>

</style>
