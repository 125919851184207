<template>
    <svg v-if="isActive" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M12.5 24C18.8513 24 24 18.8513 24 12.5C24 6.14873 18.8513 1 12.5 1C6.14873 1 1 6.14873 1 12.5C1 18.8513 6.14873 24 12.5 24ZM8.84405 10.144C8.48138 10.816 8.30005 11.6053 8.30005 12.512C8.30005 13.4293 8.48138 14.2293 8.84405 14.912C9.20672 15.5947 9.70805 16.1227 10.348 16.496C10.9987 16.8693 11.74 17.056 12.572 17.056C13.628 17.056 14.508 16.7893 15.212 16.256C15.916 15.712 16.3854 14.992 16.62 14.096H15.052C14.892 14.6507 14.5987 15.0773 14.172 15.376C13.7454 15.6747 13.212 15.824 12.572 15.824C11.74 15.824 11.068 15.536 10.556 14.96C10.044 14.384 9.78805 13.568 9.78805 12.512C9.78805 11.4667 10.044 10.6613 10.556 10.096C11.068 9.52 11.74 9.232 12.572 9.232C13.212 9.232 13.7454 9.38667 14.172 9.696C14.6094 10.0053 14.9027 10.4267 15.052 10.96H16.62C16.396 10.032 15.932 9.30667 15.228 8.784C14.5347 8.26133 13.6494 8 12.572 8C11.74 8 10.9987 8.18667 10.348 8.56C9.70805 8.93333 9.20672 9.46133 8.84405 10.144Z"
              fill="#0F243F"/>
    </svg>
    <svg v-else width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M12.5 24C18.8513 24 24 18.8513 24 12.5C24 6.14873 18.8513 1 12.5 1C6.14873 1 1 6.14873 1 12.5C1 18.8513 6.14873 24 12.5 24ZM8.84405 10.144C8.48138 10.816 8.30005 11.6053 8.30005 12.512C8.30005 13.4293 8.48138 14.2293 8.84405 14.912C9.20672 15.5947 9.70805 16.1227 10.348 16.496C10.9987 16.8693 11.74 17.056 12.572 17.056C13.628 17.056 14.508 16.7893 15.212 16.256C15.916 15.712 16.3854 14.992 16.62 14.096H15.052C14.892 14.6507 14.5987 15.0773 14.172 15.376C13.7454 15.6747 13.212 15.824 12.572 15.824C11.74 15.824 11.068 15.536 10.556 14.96C10.044 14.384 9.78805 13.568 9.78805 12.512C9.78805 11.4667 10.044 10.6613 10.556 10.096C11.068 9.52 11.74 9.232 12.572 9.232C13.212 9.232 13.7454 9.38667 14.172 9.696C14.6094 10.0053 14.9027 10.4267 15.052 10.96H16.62C16.396 10.032 15.932 9.30667 15.228 8.784C14.5347 8.26133 13.6494 8 12.572 8C11.74 8 10.9987 8.18667 10.348 8.56C9.70805 8.93333 9.20672 9.46133 8.84405 10.144Z"
              fill="white"/>
    </svg>
</template>

<script>
export default {
    props: {
        isActive: Boolean
    },
    name: "CompaniesIcon"
}
</script>

<style>

</style>
