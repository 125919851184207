<template>
  <div class="popup">
    <div class="position-relative">
      <div @click="onClose" class="popup__closeIcon">
        <close-popup-icon />
      </div>
      <div class="popup__rescheduleTitle">
        {{ $t("haveNoSaveChanges") }}
      </div>
      <div class="popup__rescheduleSubTitle">
        {{ $t("TheAppointmentWasNotRescheduled") }}
      </div>
      <div class="popup__rescheduleText">
        {{ $t("IfYouLeaveThePageTheAppointmentWillNotBeRescheduled") }}
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <div class="passwordResetPopup-cancel" @click="leave()">
          {{ $t("LeavePage") }}
        </div>
        <base-button class="passwordResetPopup-submit" @click="stay()"
          >{{ $t("StayOnThePage") }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import ClosePopupIcon from "@/components/UI/icons/addRemoveIcons/ClosePopupIcon";
import BaseButton from "@/components/UI/buttons/BaseButton";
import { mapGetters } from "vuex";

export default {
  props: {
    onClose: Function,
  },
  name: "RescheduleAppointmentPopup",
  components: { BaseButton, ClosePopupIcon },
  methods: {
    leave() {
      let changesPopup = {
        show: false,
        to: this.reschedule.to,
        save: false,
        leave: true,
      };
      this.$store.commit("SET_RESCHEDULE_APPOINTMENT_POPUP", changesPopup);
    },
    stay() {
      let changesPopup = {
        show: false,
        to: this.reschedule.to,
        save: true,
        leave: false,
      };
      this.$store.commit("SET_RESCHEDULE_APPOINTMENT_POPUP", changesPopup);
    },
  },
  computed: {
    ...mapGetters({
      reschedule: "RESCHEDULE_APPOINTMENT_POPUP",
    }),
  },
};
</script>

<style></style>
