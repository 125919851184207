<template>
    <svg v-if="isActive" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3.33301" y="10.7881" width="6.21238" height="6.21238" rx="1.49097" fill="#0F243F"/>
        <rect x="3.33301" y="3.33301" width="6.21238" height="6.21238" rx="1.49097" fill="#0F243F"/>
        <rect x="10.7879" y="10.7881" width="6.21238" height="6.21238" rx="1.49097" fill="#0F243F"/>
        <rect x="10.7879" y="3.33301" width="6.21238" height="6.21238" rx="1.49097" fill="#0F243F"/>
    </svg>
    <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3.33301" y="10.7881" width="6.21238" height="6.21238" rx="1.49097" fill="white"/>
        <rect x="3.33301" y="3.33301" width="6.21238" height="6.21238" rx="1.49097" fill="white"/>
        <rect x="10.7879" y="10.7881" width="6.21238" height="6.21238" rx="1.49097" fill="white"/>
        <rect x="10.7879" y="3.33301" width="6.21238" height="6.21238" rx="1.49097" fill="white"/>
    </svg>

</template>

<script>
export default {
    props: {
        isActive: Boolean
    },
    name: "ServicesIcon"
}
</script>

<style>

</style>
