<template>
  <div class="popup">
    <div class="position-relative">
      <div @click="onClose" class="popup__closeIcon">
        <close-popup-icon />
      </div>
      <div class="passwordResetPopup-title">
        {{ $t("haveNoSaveChanges") }}
      </div>
      <div class="passwordResetPopup-subtitle">
        {{ $t("allUnsavedInformation") }}
      </div>
      <div class="popup-description">
        {{ $t("saveChangesBeforeLeave") }}
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <div class="passwordResetPopup-cancel" @click="discard()">
          {{ $t("leaveWithoutSaving") }}
        </div>
        <base-button class="passwordResetPopup-submit" @click="save()">
          {{ $t("saveAndLeave") }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/UI/buttons/BaseButton";
import ClosePopupIcon from "@/components/UI/icons/addRemoveIcons/ClosePopupIcon";

export default {
  props: {
    onClose: Function,
  },
  components: { ClosePopupIcon, BaseButton },
  name: "saveChangesPopup",
  methods: {
    discard() {
      let changesPopup = {
        show: false,
        to: this.haveChanges.to,
        save: false,
        leave: true,
      };
      this.$store.commit("SET_STATE_POPUP", changesPopup);
    },
    save() {
      let changesPopup = {
        show: false,
        to: this.haveChanges.to,
        save: true,
        leave: false,
      };
      this.$store.commit("SET_STATE_POPUP", changesPopup);
    },
  },
  computed: {
    haveChanges() {
      return this.$store.getters.STATE_POPUP;
    },
  },
  watch: {
    haveChanges: {
      deep: true,
      handler(newCount) {},
    },
  },
};
</script>

<style></style>
