<template>
  <div class="popup">
    <div class="position-relative">
      <div @click="onClose" class="popup__closeIcon">
        <close-popup-icon />
      </div>
      <div class="passwordResetPopup-title">
        {{ $t("appointmentPageCancel") }}
      </div>
      <div class="passwordResetPopup-subtitle">
        <ul>
          <li>
            {{ $t("YouCanCancelTheAppointment") }}
          </li>
          <li>
            {{ $t("IfTheAppointmentIsCanceled") }}
          </li>
        </ul>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <div class="passwordResetPopup-cancel" @click="onClose">
          {{ $t("cancel") }}
        </div>
        <base-button
          class="passwordResetPopup-submit"
          @click="cancelAppointment()"
        >
          {{ $t("appointmentPageCancel") }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import ClosePopupIcon from "@/components/UI/icons/addRemoveIcons/ClosePopupIcon";
import BaseButton from "@/components/UI/buttons/BaseButton";
import { useToast } from "vue-toastification";

export default {
  props: {
    onClose: Function,
  },
  name: "CancelAppointment",
  components: { BaseButton, ClosePopupIcon },
  setup() {
    const toast = useToast();

    return {
      toast,
    };
  },
  methods: {
    cancelAppointment() {
      let appointment_id = this.$store.getters.APPOINTMENT_ID;
      this.$http
        .patch(`appointments/${appointment_id}/cancel`)
        .then(() => {
          this.toast.success(this.$t("AppointmentWasSuccessfullyCanceled"));
          this.$router.push("/");
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            this.toast.error(err.response.data.message);
          } else {
            this.toast.error(this.$t("NetworkError"));
          }
        });
    },
  },
};
</script>

<style scoped></style>
