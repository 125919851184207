<template>
    <svg v-if="isActive" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12.5" cy="12.5" r="10.5" fill="#0F243F"/>
        <path d="M12 5.5C12 5.22386 12.2239 5 12.5 5C12.7761 5 13 5.22386 13 5.5V13H12V5.5Z" fill="white"/>
        <path
                d="M12 13L12.9997 12.9996L9.94421 17.6826C9.79671 17.9087 9.49472 17.9739 9.26706 17.8289C9.03108 17.6785 8.96757 17.3621 9.12729 17.1323L12 13Z"
                fill="white"/>
    </svg>
    <svg v-else width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12.5" cy="12.5" r="10.5" fill="white"/>
        <path d="M12 5.5C12 5.22386 12.2239 5 12.5 5C12.7761 5 13 5.22386 13 5.5V13H12V5.5Z" fill="#0F243F"/>
        <path
                d="M12 13L12.9997 12.9996L9.94421 17.6826C9.79671 17.9087 9.49472 17.9739 9.26706 17.8289C9.03108 17.6785 8.96757 17.3621 9.12729 17.1323L12 13Z"
                fill="#0F243F"/>
    </svg>
</template>

<script>
export default {
    props: {
        isActive: Boolean
    },
    name: "AppointmentsSideBarIcon"
}
</script>

<style>

</style>
