<template>
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M22.7832 6.11633C22.3806 5.60183 21.754 5.28862 21.1053 5.28862H7.481L7.0559 3.63309C6.8098 2.67119 5.95965 2 4.97542 2H2.69348C2.31318 2 2 2.31319 2 2.69348C2 3.07378 2.31319 3.38696 2.69348 3.38696H4.97542C5.31093 3.38696 5.60183 3.61074 5.69139 3.94625L8.42073 14.8188C8.66682 15.7807 9.51697 16.4519 10.5012 16.4519H19.4275C20.4119 16.4519 21.2843 15.7807 21.5079 14.8188L23.1858 7.92839C23.3423 7.30199 23.2081 6.63096 22.783 6.11633H22.7832ZM21.8213 7.61523L20.1434 14.5057C20.054 14.8412 19.7631 15.065 19.4274 15.065H10.5012C10.1657 15.065 9.87476 14.8412 9.78519 14.5057L7.8389 6.69799H21.1053C21.329 6.69799 21.5526 6.80988 21.6869 6.98887C21.8212 7.16786 21.8883 7.39164 21.8212 7.61528L21.8213 7.61523Z"
              fill="black"/>
        <path d="M10.9711 17.3477C9.69584 17.3477 8.64453 18.3991 8.64453 19.6742C8.64453 20.9495 9.696 22.0008 10.9711 22.0008C12.2464 22.0009 13.2978 20.9495 13.2978 19.6742C13.2978 18.3991 12.2464 17.3477 10.9711 17.3477ZM10.9711 20.5915C10.4566 20.5915 10.0538 20.1889 10.0538 19.6742C10.0538 19.1597 10.4564 18.7569 10.9711 18.7569C11.4856 18.7569 11.8884 19.1596 11.8884 19.6742C11.8884 20.1664 11.4633 20.5915 10.9711 20.5915Z"
              fill="black"/>
        <path d="M18.6669 17.3477C17.3916 17.3477 16.3403 18.3991 16.3403 19.6742C16.3403 20.9495 17.3918 22.0008 18.6669 22.0008C19.9422 22.0008 20.9935 20.9493 20.9935 19.6742C20.9711 18.3991 19.9422 17.3477 18.6669 17.3477ZM18.6669 20.5915C18.1524 20.5915 17.7496 20.1889 17.7496 19.6742C17.7496 19.1597 18.1522 18.7569 18.6669 18.7569C19.1814 18.7569 19.5842 19.1596 19.5842 19.6742C19.5842 20.1664 19.1591 20.5915 18.6669 20.5915Z"
              fill="black"/>
    </svg>
</template>

<script>
export default {
    name: "CartIcon"
}
</script>

<style>

</style>
