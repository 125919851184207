<template>
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.5757 17.4243C16.8101 17.6586 17.1899 17.6586 17.4243 17.4243C17.6586 17.1899 17.6586 16.8101 17.4243 16.5757L16.5757 17.4243ZM12.8444 7.22222C12.8444 10.3273 10.3273 12.8444 7.22222 12.8444V14.0444C10.99 14.0444 14.0444 10.99 14.0444 7.22222H12.8444ZM7.22222 12.8444C4.11715 12.8444 1.6 10.3273 1.6 7.22222H0.4C0.4 10.99 3.45441 14.0444 7.22222 14.0444V12.8444ZM1.6 7.22222C1.6 4.11715 4.11715 1.6 7.22222 1.6V0.4C3.45441 0.4 0.4 3.45441 0.4 7.22222H1.6ZM7.22222 1.6C10.3273 1.6 12.8444 4.11715 12.8444 7.22222H14.0444C14.0444 3.45441 10.99 0.4 7.22222 0.4V1.6ZM11.2424 12.0909L16.5757 17.4243L17.4243 16.5757L12.0909 11.2424L11.2424 12.0909Z"
              fill="black"/>
    </svg>

</template>

<script>
export default {
    name: "SearchIcon"
}
</script>

<style>

</style>
