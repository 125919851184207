<template>
  <div class="popup">
    <div class="position-relative">
      <div @click="onClose" class="popup__closeIcon">
        <close-popup-icon />
      </div>
      <div class="passwordResetPopup-title">
        <span>{{ $t("ChangingPassword") }}</span>
      </div>
      <div class="passwordResetPopup-subtitle">
        <span v-if="stepOne">{{ $t("AreYouSureWantToChangePassword") }}</span>
        <span v-else>{{ $t("TheEmailWasSent") }}</span>
      </div>
      <div class="popup-description">
        <span v-if="stepOne">{{ $t("ClickTheSendMail") }}</span>
        <span v-else>{{ $t("FollowTheLinkL") }}</span>
      </div>
      <div v-if="stepOne" class="d-flex align-items-center justify-content-end">
        <div class="passwordResetPopup-cancel" @click="close()">
          {{ $t("cancel") }}
        </div>
        <base-button
          class="passwordResetPopup-submit"
          @click="changePassword()"
        >
          {{ $t("SendEmail") }}
        </base-button>
      </div>
      <div v-else class="d-flex align-items-center justify-content-end">
        <base-button class="passwordResetPopup-submit" @click="close()">
          {{ $t("GotIt") }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import ClosePopupIcon from "@/components/UI/icons/addRemoveIcons/ClosePopupIcon";
import BaseButton from "@/components/UI/buttons/BaseButton";

export default {
  props: {
    onClose: Function,
  },
  name: "ChangeEmployeePassword",
  components: { BaseButton, ClosePopupIcon },
  data() {
    return {
      stepOne: true,
    };
  },
  methods: {
    close() {
      this.$store.commit("SET_CHANGE_EMPLOYEE_PASSWORD_POPUP", false);
    },
    changePassword() {
      this.$http.post("users/change-password").then(() => {
        this.stepOne = false;
      });
    },
  },
};
</script>

<style></style>
