<template>
  <div class="popup popupAssign">
    <div class="position-relative">
      <div @click="onClose" class="popup__closeIcon">
        <close-popup-icon />
      </div>
      <div class="popup__title">
        <svg
          @click="back()"
          width="23"
          height="22"
          viewBox="0 0 23 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_304_7852)">
            <path
              d="M19.9371 10.6075H2.96523M2.96523 10.6075L9.86006 3.7127M2.96523 10.6075L9.86006 17.5023"
              stroke="black"
              stroke-linecap="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_304_7852">
              <rect
                width="15.0012"
                height="15.0012"
                fill="white"
                transform="matrix(-0.707107 0.707107 0.707107 0.707107 11.4512 0)"
              />
            </clipPath>
          </defs>
        </svg>
        {{
          bonusState
            ? $t("AssignCreditsToEmployees")
            : $t("WithdrawalOfCredits")
        }}
      </div>

      <div v-if="steps.select">
        <div class="popup__subtitle">
          {{
            bonusState
              ? $t("PleaseSelectUsers")
              : $t("PleaseSelectUsersWithdraw")
          }}
        </div>
        <div class="d-flex justify-content-between mb-20">
          <div class="d-flex align-items-center">
            <base-button
              class="popup__button"
              @click="selectAll()"
              :book="true"
            >
              {{
                employeesList.length === 0 ||
                selectedEmployee.length !== employeesList.length
                  ? $t("SelectAll")
                  : $t("DeselectAll")
              }}
            </base-button>
            <div @click="clear()" class="popup__reset-text cursor-pointer">
              <reset-icon />
              {{ $t("RevertSelected") }}
            </div>
          </div>
          <div class="experts__searchInput">
            <search-input
              v-model="search"
              :search-function="searchEmployee"
              @keyup.enter="searchEmployee"
              :placeholder="$t('findEmployee')"
            />
          </div>
        </div>
        <div class="popupAssign__scrolled">
          <template v-for="employee in employeesList">
            <employees-company-table
              :employee="employee"
              :is-select="isSelected(employee.id)"
              :select-employee="selectEmployee"
              :assign="true"
            />
          </template>
          <div
            v-if="employeesList && employeesList.length >= limit"
            class="w-max-content m-auto"
          >
            <base-button
              class="loadMoreBtn loadMoreBtn--assignCredits"
              @click="loadMore()"
              :load="true"
            >
              {{ $t("loadMore") }}
            </base-button>
          </div>
        </div>
      </div>
      <div v-if="steps.input">
        <div class="popup__subtitle">
          {{
            bonusState
              ? $t("PleaseEnterTheNumberOfCredits")
              : $t("PleaseEnterTheNumberOfCreditsWithdraw")
          }}
        </div>
        <div class="row">
          <div class="col-6">
            <div class="popupAssign__text popupAssign__text--hasInputs">
              <div class="popupAssign__inputTitle">
                {{ $t("CreditsPerEmployee") }}
              </div>
              <base-input
                v-model="creditsNumber"
                class="popupAssign__input"
                mask="€ #*.##"
              />
              <div class="popupAssign__inputTitle">
                {{
                  $t("InTotalFor") +
                  " " +
                  selectedEmployee.length +
                  " " +
                  $t("employees:")
                }}
              </div>
              <div class="popupAssign__total">€{{ totalCoast }}</div>
            </div>
          </div>
          <div class="col-6">
            <div class="popupAssign__text">
              <div class="popupAssign__textIcon">
                <information-icon />
              </div>
              <div>
                {{ $t("EnterNumberOfCreditsWillApply") }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        class="d-flex align-items-center mt-30"
        :class="{
          'justify-content-between': steps.select,
          'justify-content-end': steps.input,
        }"
      >
        <div v-if="steps.select" class="popup__employee-count">
          <span>{{ selectedEmployee.length }}</span>
          {{ $t("EmployeesSelected") }}
        </div>
        <div class="d-flex align-items-center">
          <div class="passwordResetPopup-cancel" @click="onClose">
            {{ $t("cancel") }}
          </div>
          <base-button
            @click="next()"
            class="popupAssign__button"
            :class="{ 'popupAssign__button--active': steps.input }"
            :header="true"
          >
            {{
              steps.select
                ? $t("Continue")
                : bonusState
                ? $t("ApplyCredits")
                : $t("WithdrawCredits")
            }}
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { clearString } from "@/helpers/clearStringToNumber";
import axios from "axios";
import { useToast } from "vue-toastification";
import ClosePopupIcon from "@/components/UI/icons/addRemoveIcons/ClosePopupIcon.vue";
import BaseButton from "@/components/UI/buttons/BaseButton.vue";
import ResetIcon from "@/components/UI/icons/filterIcons/ResetIcon.vue";
import EmployeesCompanyTable from "@/components/elements/employees/EmployeesCompanyTable.vue";
import BaseInput from "@/components/UI/inputs/BaseInput.vue";
import InformationIcon from "@/components/UI/icons/buttonIcons/InformationIcon.vue";
import SearchInput from "@/components/UI/inputs/SearchInput.vue";

export default {
  props: {
    onClose: Function,
    bonusState: Boolean,
  },
  name: "AssignBonuses",
  components: {
    SearchInput,
    InformationIcon,
    BaseInput,
    EmployeesCompanyTable,
    ResetIcon,
    BaseButton,
    ClosePopupIcon,
  },
  setup() {
    const toast = useToast();

    return {
      toast,
    };
  },
  data() {
    return {
      creditsNumber: null,
      selectedEmployee: [],
      employeesList: [],
      search: null,
      limit: 10,
      steps: {
        select: true,
        input: false,
      },
    };
  },
  async mounted() {
    this.employeesList = await this.getEmployees();
    console.log(this.bonusState);
  },
  methods: {
    async getEmployees() {
      try {
        return await axios
          .get("employees/company", {
            params: {
              search: this.search,
              limit: this.limit,
            },
          })
          .then(({ data }) => {
            return data.body;
          });
      } catch (err) {
        if (err.response.data.status === 400) {
          this.toast.error(err.response.data.message);
        } else {
          this.toast.error(this.$t("NetworkError"));
        }
      }
    },
    async searchEmployee() {
      this.employeesList = await this.getEmployees();
    },
    async loadMore() {
      this.limit += 10;
      this.employeesList = await this.getEmployees();
    },
    async clear() {
      this.selectedEmployee = [];
      this.search = null;
      this.employeesList = await this.getEmployees();
    },

    async assignBonuses() {
      let creditValue = this.bonusState
        ? clearString(this.creditsNumber)
        : clearString(this.creditsNumber) * -1;
      try {
        return await this.$http
          .patch("employees/balance", {
            value: creditValue,
            ids: this.selectedEmployee,
          })
          .then(() => {
            this.onClose();
            this.toast.success(this.$t("successfulSaveData"));
            this.$store.commit("SET_COMPANY_EMPLOYEES", null);
          });
      } catch (err) {
        if (err.response.data.status === 400) {
          this.toast.error(err.response.data.message);
        } else {
          this.toast.error(this.$t("NetworkError"));
        }
      }
    },

    selectAll() {
      if (this.selectedEmployee.length === this.employeesList.length) {
        this.selectedEmployee = [];
      } else {
        this.selectedEmployee = this.employeesList.map((e) => e.id);
      }
    },
    selectEmployee(id) {
      if (this.selectedEmployee.includes(id)) {
        this.selectedEmployee = this.selectedEmployee.filter((e) => e !== id);
      } else {
        this.selectedEmployee.push(id);
      }
    },

    isSelected(id) {
      return this.selectedEmployee.includes(id);
    },

    next() {
      if (this.steps.select) {
        this.steps.select = false;
        this.steps.input = true;
        return;
      }
      if (this.steps.input) {
        this.assignBonuses();
      }
    },
    back() {
      if (this.steps.input) {
        this.steps.select = true;
        this.steps.input = false;
        return;
      }
      if (this.steps.select) {
        this.props.onClose();
      }
    },
  },
  computed: {
    totalCoast() {
      return clearString(this.creditsNumber) * this.selectedEmployee.length;
    },
  },
};
</script>

<style scoped></style>
