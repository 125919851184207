<template>
  <div class="searchInput__container">
    <div class="searchInput__searchIcon">
      <search-icon />
    </div>
    <input
      class="searchInput"
      type="text"
      name="searchInput"
      :placeholder="placeholder"
      id="searchInput"
      @input="onInputChange"
      :value="modelValue"
    />
    <div @click="searchFunction" class="searchInput__arrowIcon">
      <horizontal-arrow />
    </div>
  </div>
</template>

<script>
import SearchIcon from "@/components/UI/icons/SearachIcon";
import HorizontalArrow from "@/components/UI/icons/arrows/HorizontalArrow";

export default {
  name: "SearchInput",
  components: { HorizontalArrow, SearchIcon },
  props: {
    modelValue: String,
    placeholder: {
      type: String,
    },
    searchFunction: Function,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    onInputChange(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
};
</script>

<style></style>
