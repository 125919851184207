<template>
  <div class="popup">
    <div class="position-relative">
      <div @click="onClose" class="popup__closeIcon">
        <close-popup-icon />
      </div>
      <div class="passwordResetPopup-title">
        {{ $t("PasswordHasNotBeenChanged") }}
      </div>
      <div class="passwordResetPopup-subtitle">
        {{ $t("allUnsavedInformation") }}
      </div>
      <div class="popup-description">
        {{ $t("saveChangesBeforeLeave") }}
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <div class="passwordResetPopup-cancel" @click="leave()">
          {{ $t("leaveWithoutSaving") }}
        </div>
        <base-button class="passwordResetPopup-submit" @click="save()">
          {{ $t("saveAndLeave") }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import ClosePopupIcon from "@/components/UI/icons/addRemoveIcons/ClosePopupIcon";
import BaseButton from "@/components/UI/buttons/BaseButton";
import { mapGetters } from "vuex";

export default {
  props: {
    onClose: Function,
  },
  name: "ChangeEmployeePasswordNoSave",
  components: { BaseButton, ClosePopupIcon },
  methods: {
    leave() {
      let changesPopup = {
        show: false,
        to: this.popupsInformation.to,
        save: false,
        leave: true,
      };
      this.$store.commit(
        "SET_CHANGE_EMPLOYEE_PASSWORD_LEAVE_POPUP",
        changesPopup
      );
    },
    save() {
      let changesPopup = {
        show: false,
        to: this.popupsInformation.to,
        save: true,
        leave: false,
      };
      this.$store.commit(
        "SET_CHANGE_EMPLOYEE_PASSWORD_LEAVE_POPUP",
        changesPopup
      );
    },
  },
  computed: {
    ...mapGetters({
      popupsInformation: "CHANGE_EMPLOYEE_PASSWORD_LEAVE_POPUP",
    }),
  },
};
</script>

<style></style>
