<template>
    <svg
            :width=width
            :height=height
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <path d="M1 11L11 1M11 1H2.875M11 1V9.125" stroke="black" stroke-width="1" stroke-linecap="round"/>
    </svg>

</template>

<script>
export default {
    props: {
        width: {
            type: String,
            default: '10px'
        },
        height: {
            type: String,
            default: '10px'
        }
    },
    name: "Arrow"
}
</script>

<style>

</style>
