<template>
    <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
                d="M12.5556 2.88889C15.1864 4.13564 17 6.85602 17 10C17 14.4183 13.4183 18 9 18C4.58172 18 1 14.4183 1 10C1 6.51675 3.22615 3.55345 6.33333 2.45522M16 2L11.6667 2L11.6667 6.33333"
                stroke="#212529" stroke-width="2" stroke-linecap="round"/>
    </svg>
</template>

<script>
export default {
    name: "ResetIcon"
}
</script>

<style>

</style>
