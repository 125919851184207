<template>
  <div class="position-relative">
    <picture v-if="imageName && userCode">
      <source type="image/webp" :srcset="`${pathToImage}.webp`" />
      <img
        class="p-0 avatar"
        :class="{ experts__avatar: experts }"
        :src="`${pathToImage}.png`"
        alt=""
      />
    </picture>
    <img
      v-else
      class="p-0 avatar"
      :class="{ experts__avatar: experts }"
      src="@/styles/img/default/avatar.svg"
      alt="default user avatar"
    />
    <div class="avatar-hovering-wrapper">
      <svg
        class="avatar-hovering"
        width="76"
        height="76"
        viewBox="0 0 76 76"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M32.1665 20.5L29.2498 26.3334H22.2498C19.3413 26.3334 17 28.6748 17 31.5833V50.2501C17 53.1587 19.3414 55.5 22.2498 55.5H53.7502C56.6587 55.5 59 53.1586 59 50.2501V31.5833C59 28.6747 56.6586 26.3334 53.7502 26.3334H46.7502L43.8335 20.5H32.1665ZM37.9999 32.7501C42.5105 32.7501 46.1664 36.4066 46.1664 40.9167C46.1664 45.4273 42.5099 49.0833 37.9999 49.0833C33.4899 49.0833 29.8334 45.4268 29.8334 40.9167C29.8334 36.4061 33.4899 32.7501 37.9999 32.7501Z"
          fill="white"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    userCode: [String, Number],
    imageName: String,
    width: String,
    height: String,
    experts: Boolean,
    userRole: String,
    size: String,
    editAccount: false,
  },
  name: "Avatar",
  data() {
    return {
      path: process.env.VUE_APP_ORIGIN_UPLOADS_PATH,
    };
  },
  computed: {
    ...mapGetters({
      loginUser: "CURRENT_USER_INFORMATION",
    }),
    pathToImage() {
      return `${this.path}/${this.userRole}/${this.userCode}/${this.imageName}-${this.size}`;
    },
  },
};
</script>

<style scoped>
img {
  width: v-bind(width) !important;
  height: v-bind(height) !important;
}
</style>
