<template>
  <div class="notificationsCards">
    <div class="row justify-content-between">
      <div class="d-flex w-auto align-items-center">
        <div class="notificationsCards__mentorAvatar">
          <avatar
            :imageName="notification.image"
            :user-code="notification.code"
            :user-role="currentUser.role_id === 4 ? 'experts' : 'employees'"
            width="40px"
            size="100"
            height="40px"
          />
        </div>
        <div class="notificationsCards__mentorName">
          <short-name :name="notification.name" />
        </div>
      </div>
      <div class="notificationsCards__time">
        <full-data
          v-if="notification.messages[0]"
          :data="notification.messages[0].date"
        />
      </div>
    </div>
    <div class="row">
      <div
        v-if="notification.messages[0]"
        class="notificationsCards__notificationText"
      >
        {{ notification.messages[0].text }}
      </div>
      <div
        v-if="
          notification.messages[0] &&
          notification.messages[0].files.length !== 0
        "
        class="notificationsCards__attachment"
      >
        <div class="notificationsCards__attachmentTitle">
          {{ notification.messages[0].files.length }}
          Attachment:
        </div>
        <div v-for="file in notification.messages[0].files" :key="file.id">
          <div class="notificationsCards__file">
            <notification-icons />
            <a :href="filePath + file.id + '/' + file.name" target="_blank">{{
              file.name
            }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="row notificationsCards__link">
      <div @click="openChat(notification.id)">
        {{ $t("notificationsOpenMessageBtn") }}
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/UI/images/Avatar";
import NotificationIcons from "@/components/UI/icons/buttonIcons/NotificationIcons.vue";
import FullData from "@/components/components-helpers/FullData";
import ShortName from "@/components/components-helpers/ShortName";
import { mapGetters } from "vuex";

export default {
  components: { ShortName, FullData, Avatar, NotificationIcons },
  props: {
    notification: Object,
  },
  name: "NotificationsCards",
  data() {
    return {
      defaultPath: process.env.VUE_APP_ORIGIN_API_PATH,
    };
  },
  methods: {
    openChat(id) {
      this.$store.commit("SET_OPEN_CHAT", id);
      this.$router.push("/chats");
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "CURRENT_USER_INFORMATION",
    }),
    filePath() {
      return `${this.defaultPath}uploads/chats/`;
    },
  },
};
</script>

<style></style>
