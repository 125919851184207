<template>
  {{ value }}
</template>

<script>
export default {
  props: {
    name: String,
  },
  name: "ShortName",
  computed: {
    value() {
      if (this.name) {
        let name = this.name[0].toUpperCase() + this.name.substring(1);
        return name
          .split(/\s+/)
          .map((w, i) => (i ? w.substring(0, 1).toUpperCase() + "." : w))
          .join(" ");
      } else {
        return "Aura Impact";
      }
    },
  },
};
</script>

<style></style>
