<template>
  <div class="popup popup__import">
    <div class="position-relative">
      <div @click="onClose" class="popup__closeIcon">
        <close-popup-icon />
      </div>
      <div class="passwordResetPopup-title">
        {{ $t("ImportEmployeesList") }}
      </div>
      <div class="passwordResetPopup-subtitle">
        <span v-if="step.upload">
          {{ $t("ImportEmployeesListDescription") }}
        </span>
        <span v-if="step.uploadInProgress">
          {{ $t("UploadEmployeeListInProgDescription") }}
        </span>
        <span v-if="step.result">
          {{
            employeeUploadResult.failed === 0
              ? $t("AllInviteSuccessfullySend")
              : $t("ImportHaveErrors")
          }}
        </span>
      </div>
      <div class="mb-20">
        <div v-if="step.upload">
          <drop-zone
            v-if="!!!file"
            class="popup__dragAndDrop"
            :uploadOnDrop="false"
            :maxFiles="1"
            :chunking="true"
            :maxFileSize="6000000"
            :acceptedFiles="['xls', 'xlsx']"
            :value="file"
            @addedFile="addFile"
          >
            <template #message>
              {{ $t("DropFileHere") }}
            </template>
          </drop-zone>
          <div v-else class="popup__fileList">
            <div>
              {{ file.file.name }}
            </div>
            <div class="red cursor-pointer" @click="remove()">
              {{ $t("remove") }}
            </div>
          </div>
        </div>
        <div v-if="step.uploadInProgress" class="popup__fileList">
          <loader-icon />
        </div>
        <div v-if="step.result" class="popup__list">
          <ul>
            <li v-for="item in employeeUploadResult.errors">
              <span class="weight-500">
                {{ item.email }}
              </span>
              -
              <span>
                {{ item.message }}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <div class="passwordResetPopup-cancel" @click="onClose">
          {{ $t("cancel") }}
        </div>
        <base-button
          v-if="step.upload"
          class="passwordResetPopup-submit"
          @click="importEmployeesList()"
          >{{ $t("Import") }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import ClosePopupIcon from "@/components/UI/icons/addRemoveIcons/ClosePopupIcon";
import BaseButton from "@/components/UI/buttons/BaseButton";
import { DropZone } from "dropzone-vue";
import LoaderIcon from "@/components/UI/icons/preloaders/LoaderIcon";
import { useToast } from "vue-toastification";

export default {
  components: { LoaderIcon, BaseButton, ClosePopupIcon, DropZone },
  props: {
    onClose: Function,
  },
  name: "ImportEmployeesList",
  setup() {
    const toast = useToast();

    return {
      toast,
    };
  },
  data() {
    return {
      step: {
        upload: true,
        uploadInProgress: false,
        result: false,
      },
      file: null,
      employeeUploadResult: {
        failed: 0,
        errors: null,
        success: null,
      },
    };
  },
  methods: {
    addFile(event) {
      this.file = event;
    },
    async importEmployeesList() {
      if (!this.file) {
        this.toast.warning(this.$t("PleaseUploadFile"));
        return;
      }
      this.step.uploadInProgress = true;
      this.step.upload = false;
      let employeeList = new FormData();
      employeeList.append("file", this.file.file);
      try {
        this.employeeUploadResult = await this.$http
          .post("/auth/invite/file", employeeList, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(({ data }) => {
            return data.body;
          });
        this.step.uploadInProgress = false;
        this.step.result = true;
        this.$store.commit("SET_COMPANY_EMPLOYEES", null);
        if (this.employeeUploadResult.failed === 0) this.onClose();
      } catch (err) {
        if (err.response.data.status === 400) {
          this.$store.commit("SET_LOADED_STATE", false);
          this.toast.error(err.response.data.message);
        } else {
          this.$store.commit("SET_LOADED_STATE", false);
          this.toast.error(this.$t("NetworkError"));
        }
      }
    },
    remove() {
      this.file = null;
    },
  },
};
</script>

<style scoped></style>
