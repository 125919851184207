<template>
  {{ value }}
</template>

<script>
export default {
  props: {
    data: Number,
  },
  name: "DurationTime",
  computed: {
    value() {
      let hours = Math.floor(this.data / 60);
      let minutes = Math.floor(this.data % 60);
      if (Number(this.data) >= 60) {
        return `${hours}h ${minutes}m`;
      } else {
        return `${minutes}m`;
      }
    },
  },
};
</script>

<style></style>
