<template>
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="15" cy="15" r="15" fill="white"/>
        <path d="M9 21L21 9" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
        <path d="M21 21L9 9" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
    </svg>
</template>

<script>
export default {
    name: "ClosePopupIcon"
}
</script>

<style>

</style>
