<template>
  <div class="passwordResetPopup-container">
    <div class="position-relative">
      <div @click="close()" class="popup__closeIcon">
        <close-popup-icon />
      </div>
      <div v-if="loginUser.role_id === 1" class="passwordResetPopup-title">
        {{ $t("enterUserCredentials") }}
      </div>
      <div v-if="loginUser.role_id === 3" class="passwordResetPopup-title">
        {{ $t("EnterEmployeeEmail") }}
      </div>
      <div
        v-if="loginUser.role_id === 1"
        class="passwordResetPopup-subtitle mb-50"
      >
        {{ $t("userCredentialsSubtitle") }}
      </div>
      <div
        v-if="loginUser.role_id === 3"
        class="passwordResetPopup-subtitle mb-50"
      >
        {{ $t("EnterEmployeeEmailForSentRegistrationMessage") }}
      </div>
      <div>
        <base-input
          class="w-100 mb-50"
          @input="checkMail()"
          v-model="email"
          :error="v$.email.$error"
          :error-message="v$.email.$errors"
          type="text"
        >
          {{ $t("enterEmail") }}
        </base-input>
        <div
          v-if="newUser && loginUser.role_id !== 3"
          class="position-relative mb-50"
        >
          <base-input
            class="w-100 passwordResetPopup__input"
            v-model="password"
            :error="v$.password.$error"
            :error-message="v$.password.$errors"
            type="text"
          >
            {{ $t("enterPassword") }}
          </base-input>
          <base-button
            class="buttons__passwordGenerate"
            @click="passwordGenerate()"
          >
            {{ $t("generate") }}
          </base-button>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <div class="passwordResetPopup-cancel" @click="close()">
          {{ $t("cancel") }}
        </div>
        <base-button class="passwordResetPopup-submit" @click="goNext()"
          >{{ $t("submit") }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import ClosePopupIcon from "@/components/UI/icons/addRemoveIcons/ClosePopupIcon";
import BaseButton from "@/components/UI/buttons/BaseButton";
import BaseInput from "@/components/UI/inputs/BaseInput";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { email, minLength, required } from "@vuelidate/validators";
import { mapGetters } from "vuex";

export default {
  props: {
    onClose: Function,
  },
  name: "CreateNewUserPopup",
  components: { BaseInput, BaseButton, ClosePopupIcon },
  setup() {
    const toast = useToast();
    const v$ = useVuelidate();

    return {
      toast,
      v$,
    };
  },
  data() {
    return {
      email: null,
      newUser: true,
      password: null,
      passwordValidation: {},
      userInformation: {},
    };
  },
  validations() {
    return {
      email: { required, email },
      password: this.passwordValidation,
    };
  },
  mounted() {
    let credentials = this.$store.getters.NEW_USER_CREDENTIALS;
    if (!!credentials) {
      this.email = credentials.email;
      this.password = credentials.password;
    }
  },
  methods: {
    close() {
      if (!this.$router.currentRoute.value.fullPath.includes("/new")) {
        this.$store.commit("SET_NEW_USER_CREDENTIALS", null);
      }
      this.onClose();
    },
    passwordGenerate() {
      this.password = Math.floor(Math.random() * 1000000000000000000).toString(
        36
      );
    },
    async checkMail() {
      this.v$.$touch();
      if (this.v$.email.$error) {
        return;
      }
      this.newUser = await this.$http
        .get("users/email/" + this.email + "/exist")
        .then(({ data }) => {
          return !data.body;
        });
      if (this.loginUser.role_id === 3) return;
      this.v$.$touch();
      if (this.v$.password.$error) {
        return;
      }
      if (this.newUser) {
        this.passwordValidation = { required, minLength: minLength(8) };
      } else {
        this.passwordValidation = {};
      }
    },
    goNext() {
      this.v$.$touch();
      if (this.v$.$error) {
        this.toast.error(this.$t("checkInputs"));
        return;
      }
      if (this.loginUser.role_id === 3) {
        this.sendRegistrationEmail();
        return;
      }
      let credentials = {
        password: this.password,
        email: this.email,
        isNewUser: this.newUser,
      };
      this.$store.commit("SET_NEW_USER_CREDENTIALS", credentials);
      if (this.$router.currentRoute.value.fullPath.includes("/new")) {
        this.onClose();
        return;
      }
      let link = this.$store.getters.USER_ROLE_CREATE + "/new";
      this.$router.push(link);
    },
    async sendRegistrationEmail() {
      try {
        await this.$http.post("/auth/invite", {
          email: this.email,
        });
        this.onClose();
        this.toast.success(this.$t("InviteSuccessfullySend"));
        this.$store.commit("SET_COMPANY_EMPLOYEES", null);
      } catch (err) {
        if (err.response.data.status === 400) {
          this.toast.error(err.response.data.message);
        } else {
          this.toast.error(this.$t("NetworkError"));
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      loginUser: "CURRENT_USER_INFORMATION",
    }),
  },
};
</script>

<style></style>
