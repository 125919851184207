<template>
    <svg width="60" height="57" viewBox="0 0 60 57" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
                d="M59.8002 34.0002V53.2002V54.7002V56.2002H0.200195V54.7002V53.2002V34.0002H3.2002V53.2002H56.8002V34.0002H59.8002ZM31.4002 44.6002L30.0002 45.9002L28.6002 44.5002L14.9002 30.8002L17.0002 28.7002L28.4002 40.1002V0.200195H31.4002V40.2002L42.8002 28.8002L44.9002 30.9002L31.4002 44.6002Z"
                fill="black"/>
    </svg>

</template>

<script>
export default {
    name: "DragAndDropIcon"
}
</script>

<style>

</style>
