<template>
  <div class="popup">
    <div class="position-relative">
      <div @click="onClose" class="popup__closeIcon">
        <close-popup-icon />
      </div>
      <div class="passwordResetPopup-title">
        {{ $t("Delete") }}
        <span
          v-if="
            this.$route.path === '/expert/edit/' + this.$route.params.expert
          "
          >{{ $t("expert") }}</span
        >
        <span
          v-if="this.$route.path === '/employee/' + this.$route.params.employee"
          >{{ $t("employee") }}</span
        >
        <span v-if="this.$route.path === '/employees'">{{
          $t("employee")
        }}</span>
        <span
          v-if="this.$route.path === '/company/' + this.$route.params.company"
          >{{ $t("company") }}</span
        >
        <span
          v-if="
            this.$route.path === '/service/edit/' + this.$route.params.service
          "
          >{{ $t("service") }}</span
        >
        <span
          v-if="this.$route.path === '/blog/edit/' + this.$route.params.blog"
          >{{ $t("post") }}</span
        >?
      </div>
      <div class="passwordResetPopup-subtitle">
        <span
          v-if="
            this.$route.path === '/expert/edit/' + this.$route.params.expert
          "
        >
          {{ $t("sureDeleteExpert") }}
          {{ `${userInformation.first_name} ${userInformation.last_name}` }}?
        </span>
        <span
          v-if="this.$route.path === '/employee/' + this.$route.params.employee"
        >
          {{ $t("sureDeleteExpert") }}
          {{ `${userInformation.first_name} ${userInformation.last_name}` }}?
        </span>
        <span v-if="this.$route.path === '/employees'">
          {{ $t("sureDeleteExpert") }} {{ `${userInformation.email}` }}?
        </span>
        <span
          v-if="
            this.$route.path === '/service/edit/' + this.$route.params.service
          "
        >
          {{ $t("sureDeleteExpert") }} {{ userInformation.serviceName }}
          {{ $t("service") }}?
        </span>
      </div>
      <div class="popup-description">
        <span
          v-if="
            this.$route.path === '/expert/edit/' + this.$route.params.expert
          "
        >
          {{ $t("deleteExpertDescription") }}
        </span>
        <span
          v-if="this.$route.path === '/employee/' + this.$route.params.employee"
        >
          {{ $t("deleteEmployeeDescription") }}
        </span>
        <span v-if="this.$route.path === '/employees'">
          {{ $t("deleteEmployeeDescription") }}
        </span>
        <span
          v-if="this.$route.path === '/company/' + this.$route.params.company"
        >
          {{ $t("afterDeleteCompanyText") }}
        </span>
        <span
          v-if="
            this.$route.path === '/service/edit/' + this.$route.params.service
          "
        >
          {{ $t("afterDeleteServiceText") }}
        </span>
        <span
          v-if="this.$route.path === '/blog/edit/' + this.$route.params.blog"
        >
          {{ $t("AfterDeleteServiceText") }}
        </span>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <div class="passwordResetPopup-cancel" @click="onClose">
          {{ $t("cancel") }}
        </div>
        <base-button class="passwordResetPopup-submit" @click="deleteUser()">{{
          $t("Delete")
        }}</base-button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/UI/buttons/BaseButton";
import ClosePopupIcon from "@/components/UI/icons/addRemoveIcons/ClosePopupIcon";
import { useToast } from "vue-toastification";
import { mapGetters } from "vuex";
import useVuelidate from "@vuelidate/core";

export default {
  props: {
    onClose: Function,
  },
  name: "DeletePopup",
  components: { ClosePopupIcon, BaseButton },
  setup() {
    const toast = useToast();
    const v$ = useVuelidate();

    return {
      toast,
      v$,
    };
  },
  data() {
    return {
      userInformation: {},
    };
  },
  mounted() {
    this.userInformation =
      this.loginUser.role_id === 3
        ? this.$store.getters.DELETE_EMPLOYEE_POPUP
        : this.$store.getters.EDIT_USER_INFORMATION;
  },
  methods: {
    deleteUser() {
      if (this.userInformation.role_id === 6) {
        this.$http
          .delete(`/blog/posts/${this.$route.params.blog}`)
          .then(() => {
            this.onClose();
            this.toast.success(this.$t("dataSuccessesDelete"));
            this.$router.push("/blog");
          })
          .catch((err) => {
            if (err.response.data.status === 400) {
              this.$store.commit("SET_LOADED_STATE", false);
              this.toast.error(err.response.data.message);
            } else {
              this.$store.commit("SET_LOADED_STATE", false);
              this.toast.error(this.$t("NetworkError"));
            }
          });
      } else if (this.userInformation.role_id === 5) {
        this.$http
          .delete(`services/${this.userInformation.user_id}`)
          .then(() => {
            this.onClose();
            this.toast.success(this.$t("dataSuccessesDelete"));
            this.$router.push("/services");
          })
          .catch((err) => {
            if (err.response.data.status === 400) {
              this.$store.commit("SET_LOADED_STATE", false);
              this.toast.error(err.response.data.message);
            } else {
              this.$store.commit("SET_LOADED_STATE", false);
              this.toast.error(this.$t("NetworkError"));
            }
          });
      } else if (this.userInformation.role_id === 4) {
        this.$http
          .delete(`employees/${this.userInformation.user_id}`)
          .then(() => {
            this.onClose();
            this.toast.success(this.$t("dataSuccessesDelete"));
            if (this.loginUser.role_id === 3)
              this.$store.commit("SET_COMPANY_EMPLOYEES", null);
            this.$router.push("/employees");
          })
          .catch((err) => {
            if (err.response.data.status === 400) {
              this.$store.commit("SET_LOADED_STATE", false);
              this.toast.error(err.response.data.message);
            } else {
              this.$store.commit("SET_LOADED_STATE", false);
              this.toast.error(this.$t("NetworkError"));
            }
          });
      } else if (this.userInformation.role_id === 3) {
        this.$http
          .delete(`companies/${this.userInformation.user_id}`)
          .then(() => {
            this.$store.commit("SET_COMPANIES_ITEMS", null);
            this.onClose();
            this.toast.success(this.$t("dataSuccessesDelete"));
            this.$router.push("/companies");
          })
          .catch((err) => {
            if (err.response.data.status === 400) {
              this.$store.commit("SET_LOADED_STATE", false);
              this.toast.error(err.response.data.message);
            } else {
              this.$store.commit("SET_LOADED_STATE", false);
              this.toast.error(this.$t("NetworkError"));
            }
          });
      } else if (this.userInformation.role_id === 2) {
        this.$http
          .delete(`experts/${this.userInformation.user_id}`)
          .then(() => {
            this.onClose();
            this.toast.success(this.$t("dataSuccessesDelete"));
            this.$router.push("/experts");
          })
          .catch((err) => {
            if (err.response.data.status === 400) {
              this.$store.commit("SET_LOADED_STATE", false);
              this.toast.error(err.response.data.message);
            } else {
              this.$store.commit("SET_LOADED_STATE", false);
              this.toast.error(this.$t("NetworkError"));
            }
          });
      }
    },
  },
  computed: {
    ...mapGetters({
      loginUser: "CURRENT_USER_INFORMATION",
    }),
  },
};
</script>

<style></style>
