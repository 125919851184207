<template>
    <svg v-if="isActive" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.98828 11.4849L19.8672 4.04936V3.61754C19.8672 3.1719 19.5058 2.81055 19.0602 2.81055H0.916372C0.470723 2.81055 0.109375 3.1719 0.109375 3.61754V4.04936L9.98828 11.4849Z"
              fill="#0F243F"/>
        <path d="M10.5163 13.286C10.3599 13.4036 10.174 13.4625 9.98828 13.4625C9.80256 13.4625 9.61662 13.4036 9.46031 13.286L0.109375 6.24756V16.36C0.109375 16.8057 0.470723 17.167 0.916372 17.167H19.0602C19.5058 17.167 19.8672 16.8057 19.8672 16.36V6.24756L10.5163 13.286Z"
              fill="#0F243F"/>
    </svg>
    <svg v-else width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.98828 11.4849L19.8672 4.04936V3.61754C19.8672 3.1719 19.5058 2.81055 19.0602 2.81055H0.916372C0.470723 2.81055 0.109375 3.1719 0.109375 3.61754V4.04936L9.98828 11.4849Z"
              fill="white"/>
        <path d="M10.5163 13.286C10.3599 13.4036 10.174 13.4625 9.98828 13.4625C9.80256 13.4625 9.61662 13.4036 9.46031 13.286L0.109375 6.24756V16.36C0.109375 16.8057 0.470723 17.167 0.916372 17.167H19.0602C19.5058 17.167 19.8672 16.8057 19.8672 16.36V6.24756L10.5163 13.286Z"
              fill="white"/>
    </svg>


</template>

<script>
export default {
    props: {
        isActive: Boolean
    },
    name: "NewsletterIcon"
}
</script>

<style>

</style>
