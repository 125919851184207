<template>
  <div class="popup popup-company-logo">
    <div class="position-relative">
      <div @click="onClose" class="popup__closeIcon">
        <close-popup-icon />
      </div>
      <div
        v-if="currentUser.role_id === 4 || currentUser.role_id === 2"
        class="passwordResetPopup-title"
      >
        {{ $t("UploadNewAvatar") }}
      </div>
      <div
        v-if="currentUser.role_id === 3 || userInformation.role_id === 3"
        class="passwordResetPopup-title"
      >
        {{ $t("UploadCompanyLogo") }}
      </div>
      <div class="popup-description">
        <drag-and-drop
          :avatar-on-account="
            this.$route.path === '/account' && currentUser.role_id !== 3
          "
          class="popup__company-logo-drop-zone"
          v-model="thumbnail"
        />
      </div>
      <div class="d-flex align-items-center justify-content-between">
        <base-button class="popup__remove-logo-btn" @click="removeLogo()"
          >{{ $t("RemoveLogo") }}
        </base-button>
        <div class="d-flex align-items-center">
          <div class="passwordResetPopup-cancel" @click="onClose">
            {{ $t("cancel") }}
          </div>
          <base-button
            class="passwordResetPopup-submit"
            @click="saveInformation()"
            >{{ $t("submit") }}
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DragAndDrop from "@/components/elements/DragAndDrop";
import BaseButton from "@/components/UI/buttons/BaseButton";
import { mapGetters } from "vuex";
import ClosePopupIcon from "@/components/UI/icons/addRemoveIcons/ClosePopupIcon";

export default {
  components: { ClosePopupIcon, BaseButton, DragAndDrop },
  props: {
    onClose: Function,
  },
  name: "ChangeCompanyImagePopup",
  data() {
    return {
      userInformation: {},
      companyInformation: {},
      thumbnail: null,
    };
  },
  mounted() {
    this.userInformation = this.$store.getters.EDIT_USER_INFORMATION;
  },
  methods: {
    removeLogo() {
      this.thumbnail = null;
      this.saveInformation();
    },
    saveInformation() {
      this.$store.commit("SET_THUMBNAIL", this.thumbnail);
      this.onClose();
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "CURRENT_USER_INFORMATION",
    }),
  },
};
</script>

<style></style>
