<template>
    <svg style="cursor: pointer" width="13" height="13" viewBox="0 0 13 13" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M1.7002 11.2997L11.3002 1.69971" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
        <path d="M11.3002 11.2998L1.7002 1.6998" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
    </svg>
</template>

<script>
export default {
    name: "CloseButtonIcon"
}
</script>

<style>

</style>
