<template>
    <svg v-if="isActive" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2503_3608)">
            <path
                    d="M0.666407 15.776V16.4691C0.666407 16.8979 0.836742 17.3093 1.14001 17.6126C1.44329 17.9159 1.85453 18.0862 2.28354 18.0862H9.95356C10.3785 18.0862 10.7864 17.9191 11.089 17.6208C11.3916 17.3226 11.5647 16.9171 11.5707 16.4922H17.7159C18.1449 16.4922 18.5562 16.3218 18.8595 16.0186C19.1628 15.7153 19.3331 15.3039 19.3331 14.875V14.1589C19.3331 12.9027 18.8341 11.6982 17.9461 10.81C17.0578 9.92178 15.8532 9.42285 14.5972 9.42285H13.1647C11.5392 9.42334 10.0286 10.2616 9.16803 11.6408C8.46266 11.2445 7.66674 11.0376 6.85778 11.04H5.40229C4.1463 11.04 2.94167 11.5391 2.05342 12.4272C1.16534 13.3153 0.666407 14.52 0.666407 15.776ZM13.1648 10.3469H14.6203C15.6274 10.353 16.5909 10.7574 17.3009 11.4716C18.0108 12.1858 18.4091 13.1519 18.4091 14.1589V14.8521C18.4091 15.0358 18.3361 15.2121 18.2061 15.3421C18.0762 15.472 17.8999 15.545 17.716 15.545H11.5708C11.5084 14.2387 10.9059 13.0169 9.90756 12.1721C10.5973 11.0338 11.8339 10.3409 13.1648 10.3469ZM5.40241 11.9642H6.85791C7.86493 11.9702 8.82863 12.3745 9.53849 13.0887C10.2484 13.8029 10.6467 14.769 10.6467 15.7761V16.4692C10.6467 16.6529 10.5737 16.8292 10.4437 16.9592C10.3138 17.0891 10.1375 17.1623 9.9536 17.1623H2.28358C2.09971 17.1623 1.92341 17.0891 1.79352 16.9592C1.66348 16.8292 1.59047 16.6529 1.59047 16.4692V15.7761C1.59047 14.765 1.99206 13.7955 2.7069 13.0807C3.42176 12.3658 4.39151 11.9642 5.40241 11.9642Z"
                    fill="#0F243F"/>
            <path
                    d="M5.40241 11.9642H6.85791C7.86493 11.9702 8.82863 12.3745 9.53849 13.0887C10.2484 13.8029 10.6467 14.769 10.6467 15.7761V16.4692C10.6467 16.6529 10.5737 16.8292 10.4437 16.9592C10.3138 17.0891 10.1375 17.1623 9.9536 17.1623H2.28358C2.09971 17.1623 1.92341 17.0891 1.79352 16.9592C1.66348 16.8292 1.59047 16.6529 1.59047 16.4692V15.7761C1.59047 14.765 1.99206 13.7955 2.7069 13.0807C3.42176 12.3658 4.39151 11.9642 5.40241 11.9642Z"
                    fill="#0F243F"/>
            <path
                    d="M13.1648 10.3469H14.6203C15.6274 10.353 16.5909 10.7574 17.3009 11.4716C18.0108 12.1858 18.4091 13.1519 18.4091 14.1589V14.8521C18.4091 15.0358 18.3361 15.2121 18.2061 15.3421C18.0762 15.472 17.8999 15.545 17.716 15.545H11.5708C11.5084 14.2387 10.9059 13.0169 9.90756 12.1721C10.5973 11.0338 11.8339 10.3409 13.1648 10.3469Z"
                    fill="#0F243F"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0.666407 15.7692V16.4623C0.666407 16.8911 0.836742 17.3025 1.14001 17.6058C1.44329 17.9091 1.85453 18.0794 2.28354 18.0794H9.95355C10.3785 18.0794 10.7864 17.9123 11.089 17.614C11.3916 17.3158 11.5647 16.9103 11.5707 16.4854L11.5708 15.5382C11.5084 14.2319 10.9059 13.0101 9.90756 12.1653L9.16803 11.634C8.46266 11.2377 7.66674 11.0308 6.85778 11.0332H5.40229C4.1463 11.0332 2.94167 11.5323 2.05342 12.4204C1.16534 13.3085 0.666407 14.5132 0.666407 15.7692Z"
                  fill="#0F243F" stroke="#F1F7FB" stroke-width="1.5"/>
            <path
                    d="M9.46855 6.88107C9.46855 5.99265 9.11563 5.14063 8.48731 4.51249C7.85913 3.88418 7.00715 3.53125 6.11873 3.53125C5.23015 3.53125 4.37817 3.88417 3.74999 4.51249C3.12168 5.14067 2.76875 5.99265 2.76875 6.88107C2.76875 7.76964 3.12167 8.62163 3.74999 9.24981C4.37817 9.87812 5.23015 10.231 6.11873 10.231C7.00715 10.231 7.85917 9.87813 8.48731 9.24981C9.11562 8.62163 9.46855 7.76964 9.46855 6.88107ZM3.71603 6.88107C3.71603 6.23647 3.97275 5.61831 4.42928 5.16319C4.88597 4.70811 5.50495 4.45349 6.14955 4.45543C6.79431 4.45753 7.41152 4.71617 7.86532 5.17417C8.31895 5.63217 8.57164 6.25194 8.56747 6.8967C8.56344 7.5413 8.30286 8.15785 7.84341 8.61004C7.38396 9.06223 6.76337 9.31297 6.11877 9.30687C5.47529 9.30687 4.85841 9.05128 4.4035 8.59634C3.94859 8.14143 3.69297 7.52455 3.69297 6.88107H3.71603Z"
                    fill="#0F243F"/>
            <path
                    d="M3.71603 6.88107C3.71603 6.23647 3.97275 5.61831 4.42928 5.16319C4.88597 4.70811 5.50495 4.45349 6.14955 4.45543C6.79431 4.45753 7.41152 4.71617 7.86532 5.17417C8.31895 5.63217 8.57164 6.25194 8.56747 6.8967C8.56344 7.5413 8.30286 8.15785 7.84341 8.61004C7.38396 9.06223 6.76337 9.31297 6.11877 9.30687C5.47529 9.30687 4.85841 9.05128 4.4035 8.59634C3.94859 8.14143 3.69297 7.52455 3.69297 6.88107H3.71603Z"
                    fill="#0F243F"/>
            <path
                    d="M17.231 5.26388C17.231 4.37547 16.8781 3.52344 16.2497 2.89513C15.6216 2.26695 14.7696 1.91406 13.881 1.91406C12.9926 1.91406 12.1406 2.26698 11.5124 2.89513C10.8841 3.52344 10.5312 4.37547 10.5312 5.26388C10.5312 6.15229 10.8841 7.00444 11.5124 7.63262C12.1406 8.2608 12.9926 8.6137 13.881 8.6137C14.7696 8.6137 15.6216 8.26078 16.2497 7.63262C16.8781 7.00444 17.231 6.15229 17.231 5.26388ZM11.4785 5.26388C11.4785 4.61928 11.7352 4.00112 12.1917 3.546C12.6484 3.09076 13.2674 2.83614 13.912 2.83824C14.5567 2.84034 15.174 3.09898 15.6278 3.55698C16.0814 4.01498 16.3341 4.63475 16.3299 5.27951C16.3259 5.92411 16.0653 6.54066 15.6058 6.99285C15.1464 7.44504 14.5258 7.69578 13.881 7.68968C13.2377 7.68968 12.6207 7.4341 12.1659 6.97915C11.711 6.52424 11.4554 5.9072 11.4554 5.26388H11.4785Z"
                    fill="#0F243F"/>
            <path
                    d="M11.4785 5.26388C11.4785 4.61928 11.7352 4.00112 12.1917 3.546C12.6484 3.09076 13.2674 2.83614 13.912 2.83824C14.5567 2.84034 15.174 3.09898 15.6278 3.55698C16.0814 4.01498 16.3341 4.63475 16.3299 5.27951C16.3259 5.92411 16.0653 6.54066 15.6058 6.99285C15.1464 7.44504 14.5258 7.69578 13.881 7.68968C13.2377 7.68968 12.6207 7.4341 12.1659 6.97915C11.711 6.52424 11.4554 5.9072 11.4554 5.26388H11.4785Z"
                    fill="#0F243F"/>
        </g>
        <defs>
            <clipPath id="clip0_2503_3608">
                <rect width="20" height="20" fill="white"/>
            </clipPath>
        </defs>
    </svg>
    <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2503_3677)">
            <path
                    d="M0.666407 15.776V16.4691C0.666407 16.8979 0.836742 17.3093 1.14001 17.6126C1.44329 17.9159 1.85453 18.0862 2.28354 18.0862H9.95356C10.3785 18.0862 10.7864 17.9191 11.089 17.6208C11.3916 17.3226 11.5647 16.9171 11.5707 16.4922H17.7159C18.1449 16.4922 18.5562 16.3218 18.8595 16.0186C19.1628 15.7153 19.3331 15.3039 19.3331 14.875V14.1589C19.3331 12.9027 18.8341 11.6982 17.9461 10.81C17.0578 9.92178 15.8532 9.42285 14.5972 9.42285H13.1647C11.5392 9.42334 10.0286 10.2616 9.16803 11.6408C8.46266 11.2445 7.66674 11.0376 6.85778 11.04H5.40229C4.1463 11.04 2.94167 11.5391 2.05342 12.4272C1.16534 13.3153 0.666407 14.52 0.666407 15.776ZM13.1648 10.3469H14.6203C15.6274 10.353 16.5909 10.7574 17.3009 11.4716C18.0108 12.1858 18.4091 13.1519 18.4091 14.1589V14.8521C18.4091 15.0358 18.3361 15.2121 18.2061 15.3421C18.0762 15.472 17.8999 15.545 17.716 15.545H11.5708C11.5084 14.2387 10.9059 13.0169 9.90756 12.1721C10.5973 11.0338 11.8339 10.3409 13.1648 10.3469ZM5.40241 11.9642H6.85791C7.86493 11.9702 8.82863 12.3745 9.53849 13.0887C10.2484 13.8029 10.6467 14.769 10.6467 15.7761V16.4692C10.6467 16.6529 10.5737 16.8292 10.4437 16.9592C10.3138 17.0891 10.1375 17.1623 9.9536 17.1623H2.28358C2.09971 17.1623 1.92341 17.0891 1.79352 16.9592C1.66348 16.8292 1.59047 16.6529 1.59047 16.4692V15.7761C1.59047 14.765 1.99206 13.7955 2.7069 13.0807C3.42176 12.3658 4.39151 11.9642 5.40241 11.9642Z"
                    fill="#F1F7FB"/>
            <path
                    d="M5.40241 11.9642H6.85791C7.86493 11.9702 8.82863 12.3745 9.53849 13.0887C10.2484 13.8029 10.6467 14.769 10.6467 15.7761V16.4692C10.6467 16.6529 10.5737 16.8292 10.4437 16.9592C10.3138 17.0891 10.1375 17.1623 9.9536 17.1623H2.28358C2.09971 17.1623 1.92341 17.0891 1.79352 16.9592C1.66348 16.8292 1.59047 16.6529 1.59047 16.4692V15.7761C1.59047 14.765 1.99206 13.7955 2.7069 13.0807C3.42176 12.3658 4.39151 11.9642 5.40241 11.9642Z"
                    fill="#F1F7FB"/>
            <path
                    d="M13.1648 10.3469H14.6203C15.6274 10.353 16.5909 10.7574 17.3009 11.4716C18.0108 12.1858 18.4091 13.1519 18.4091 14.1589V14.8521C18.4091 15.0358 18.3361 15.2121 18.2061 15.3421C18.0762 15.472 17.8999 15.545 17.716 15.545H11.5708C11.5084 14.2387 10.9059 13.0169 9.90756 12.1721C10.5973 11.0338 11.8339 10.3409 13.1648 10.3469Z"
                    fill="#F1F7FB"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M0.666407 15.7692V16.4623C0.666407 16.8911 0.836742 17.3025 1.14001 17.6058C1.44329 17.9091 1.85453 18.0794 2.28354 18.0794H9.95355C10.3785 18.0794 10.7864 17.9123 11.089 17.614C11.3916 17.3158 11.5647 16.9103 11.5707 16.4854L11.5708 15.5382C11.5084 14.2319 10.9059 13.0101 9.90756 12.1653L9.16803 11.634C8.46266 11.2377 7.66674 11.0308 6.85778 11.0332H5.40229C4.1463 11.0332 2.94167 11.5323 2.05342 12.4204C1.16534 13.3085 0.666407 14.5132 0.666407 15.7692Z"
                  fill="#F1F7FB" stroke="#0F243F" stroke-width="1.5"/>
            <path
                    d="M9.46855 6.88107C9.46855 5.99265 9.11563 5.14063 8.48731 4.51249C7.85913 3.88418 7.00715 3.53125 6.11873 3.53125C5.23015 3.53125 4.37817 3.88417 3.74999 4.51249C3.12168 5.14067 2.76875 5.99265 2.76875 6.88107C2.76875 7.76964 3.12167 8.62163 3.74999 9.24981C4.37817 9.87812 5.23015 10.231 6.11873 10.231C7.00715 10.231 7.85917 9.87813 8.48731 9.24981C9.11562 8.62163 9.46855 7.76964 9.46855 6.88107ZM3.71603 6.88107C3.71603 6.23647 3.97275 5.61831 4.42928 5.16319C4.88597 4.70811 5.50495 4.45349 6.14955 4.45543C6.79431 4.45753 7.41152 4.71617 7.86532 5.17417C8.31895 5.63217 8.57164 6.25194 8.56747 6.8967C8.56344 7.5413 8.30286 8.15785 7.84341 8.61004C7.38396 9.06223 6.76337 9.31297 6.11877 9.30687C5.47529 9.30687 4.85841 9.05128 4.4035 8.59634C3.94859 8.14143 3.69297 7.52455 3.69297 6.88107H3.71603Z"
                    fill="#F1F7FB"/>
            <path
                    d="M3.71603 6.88107C3.71603 6.23647 3.97275 5.61831 4.42928 5.16319C4.88597 4.70811 5.50495 4.45349 6.14955 4.45543C6.79431 4.45753 7.41152 4.71617 7.86532 5.17417C8.31895 5.63217 8.57164 6.25194 8.56747 6.8967C8.56344 7.5413 8.30286 8.15785 7.84341 8.61004C7.38396 9.06223 6.76337 9.31297 6.11877 9.30687C5.47529 9.30687 4.85841 9.05128 4.4035 8.59634C3.94859 8.14143 3.69297 7.52455 3.69297 6.88107H3.71603Z"
                    fill="#F1F7FB"/>
            <path
                    d="M17.231 5.26388C17.231 4.37547 16.8781 3.52344 16.2497 2.89513C15.6216 2.26695 14.7696 1.91406 13.881 1.91406C12.9926 1.91406 12.1406 2.26698 11.5124 2.89513C10.8841 3.52344 10.5312 4.37547 10.5312 5.26388C10.5312 6.15229 10.8841 7.00444 11.5124 7.63262C12.1406 8.2608 12.9926 8.6137 13.881 8.6137C14.7696 8.6137 15.6216 8.26078 16.2497 7.63262C16.8781 7.00444 17.231 6.15229 17.231 5.26388ZM11.4785 5.26388C11.4785 4.61928 11.7352 4.00112 12.1917 3.546C12.6484 3.09076 13.2674 2.83614 13.912 2.83824C14.5567 2.84034 15.174 3.09898 15.6278 3.55698C16.0814 4.01498 16.3341 4.63475 16.3299 5.27951C16.3259 5.92411 16.0653 6.54066 15.6058 6.99285C15.1464 7.44504 14.5258 7.69578 13.881 7.68968C13.2377 7.68968 12.6207 7.4341 12.1659 6.97915C11.711 6.52424 11.4554 5.9072 11.4554 5.26388H11.4785Z"
                    fill="#F1F7FB"/>
            <path
                    d="M11.4785 5.26388C11.4785 4.61928 11.7352 4.00112 12.1917 3.546C12.6484 3.09076 13.2674 2.83614 13.912 2.83824C14.5567 2.84034 15.174 3.09898 15.6278 3.55698C16.0814 4.01498 16.3341 4.63475 16.3299 5.27951C16.3259 5.92411 16.0653 6.54066 15.6058 6.99285C15.1464 7.44504 14.5258 7.69578 13.881 7.68968C13.2377 7.68968 12.6207 7.4341 12.1659 6.97915C11.711 6.52424 11.4554 5.9072 11.4554 5.26388H11.4785Z"
                    fill="#F1F7FB"/>
        </g>
        <defs>
            <clipPath id="clip0_2503_3677">
                <rect width="20" height="20" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    props: {
        isActive: Boolean,
        width: {
            type: String,
            default: '25px'
        },
        height: {
            type: String,
            default: '22px'
        }
    },
    name: "ExpertsIcon"
}
</script>

<style>

</style>
