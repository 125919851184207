<template>
    <svg v-if="isActive" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M12.5 24C18.8513 24 24 18.8513 24 12.5C24 6.14873 18.8513 1 12.5 1C6.14873 1 1 6.14873 1 12.5C1 18.8513 6.14873 24 12.5 24ZM15.14 7.6C14.4893 7.00267 13.636 6.704 12.58 6.704C11.8867 6.704 11.2733 6.83733 10.74 7.104C10.2173 7.37067 9.812 7.74933 9.524 8.24C9.236 8.72 9.092 9.28 9.092 9.92H10.468C10.468 9.30133 10.6547 8.816 11.028 8.464C11.412 8.10133 11.9347 7.92 12.596 7.92C13.2467 7.92 13.764 8.10667 14.148 8.48C14.532 8.85333 14.724 9.36 14.724 10C14.724 10.8853 14.4253 11.488 13.828 11.808C13.2307 12.128 12.404 12.288 11.348 12.288H10.884L10.948 14.816H12.212L12.26 13.296C13.444 13.296 14.3827 13.0293 15.076 12.496C15.7693 11.952 16.116 11.12 16.116 10C16.116 8.99733 15.7907 8.19733 15.14 7.6ZM10.868 17.808C11.06 18 11.2947 18.096 11.572 18.096C11.8387 18.096 12.0627 18 12.244 17.808C12.436 17.616 12.532 17.3813 12.532 17.104C12.532 16.8267 12.436 16.592 12.244 16.4C12.0627 16.208 11.8387 16.112 11.572 16.112C11.2947 16.112 11.06 16.208 10.868 16.4C10.676 16.592 10.58 16.8267 10.58 17.104C10.58 17.3813 10.676 17.616 10.868 17.808Z"
              fill="#0F243F"/>
    </svg>
    <svg v-else width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M12.5 24C18.8513 24 24 18.8513 24 12.5C24 6.14873 18.8513 1 12.5 1C6.14873 1 1 6.14873 1 12.5C1 18.8513 6.14873 24 12.5 24ZM15.14 7.6C14.4893 7.00267 13.636 6.704 12.58 6.704C11.8867 6.704 11.2733 6.83733 10.74 7.104C10.2173 7.37067 9.812 7.74933 9.524 8.24C9.236 8.72 9.092 9.28 9.092 9.92H10.468C10.468 9.30133 10.6547 8.816 11.028 8.464C11.412 8.10133 11.9347 7.92 12.596 7.92C13.2467 7.92 13.764 8.10667 14.148 8.48C14.532 8.85333 14.724 9.36 14.724 10C14.724 10.8853 14.4253 11.488 13.828 11.808C13.2307 12.128 12.404 12.288 11.348 12.288H10.884L10.948 14.816H12.212L12.26 13.296C13.444 13.296 14.3827 13.0293 15.076 12.496C15.7693 11.952 16.116 11.12 16.116 10C16.116 8.99733 15.7907 8.19733 15.14 7.6ZM10.868 17.808C11.06 18 11.2947 18.096 11.572 18.096C11.8387 18.096 12.0627 18 12.244 17.808C12.436 17.616 12.532 17.3813 12.532 17.104C12.532 16.8267 12.436 16.592 12.244 16.4C12.0627 16.208 11.8387 16.112 11.572 16.112C11.2947 16.112 11.06 16.208 10.868 16.4C10.676 16.592 10.58 16.8267 10.58 17.104C10.58 17.3813 10.676 17.616 10.868 17.808Z"
              fill="white"/>
    </svg>
</template>

<script>
export default {
    props: {
        isActive: Boolean
    },
    name: "SupportSidebarIcon"
}
</script>

<style>

</style>
