<template>
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="11" y="2" width="17" height="22" rx="1" fill="#0F243F"/>
        <rect x="1.5" y="5.5" width="17" height="22" rx="1.5" fill="#0F243F" stroke="white"/>
        <rect x="4" y="8" width="13" height="2" rx="1" fill="white"/>
        <rect x="4" y="12" width="8" height="2" rx="1" fill="white"/>
        <rect x="4" y="16" width="11" height="2" rx="1" fill="white"/>
        <rect x="4" y="20" width="8" height="2" rx="1" fill="white"/>
    </svg>
</template>

<script>
export default {
    name: "NotificationIcons"
}
</script>

<style>

</style>
