<template>
  <div class="dragAndDrop">
    <drop-zone
      class="dragAndDrop__area"
      :class="{
        'dragAndDrop__area-avatar': avatar,
        'dragAndDrop__areaAccount-avatar': avatarOnAccount,
      }"
      :uploadOnDrop="false"
      :maxFiles="1"
      :chunking="true"
      ref="dropzone"
      :maxFileSize="6000000"
      :acceptedFiles="['image']"
      :value="modelValue"
      @addedFile="onInputChange"
    />
    <div
      class="dragAndDrop__stepTwo"
      :class="{ 'dragAndDrop__areaAccount-text': avatarOnAccount }"
    >
      <drag-and-drop-icon
        :class="{ 'dragAndDrop__area-haveImage': haveOldImage }"
      />
      <div
        v-if="!!!modelValue"
        class="dragAndDrop__dragAndDrop-text"
        :class="{ 'dragAndDrop__area-haveImage': haveOldImage }"
      >
        {{ $t("dragAndDropMessage") }}
      </div>
      <base-button
        v-if="!!!modelValue"
        class="dragAndDrop__btn"
        margin-left="0"
        :calendar="true"
        >{{ $t("choseFile") }}
      </base-button>
    </div>
  </div>
</template>

<script>
import DragAndDropIcon from "@/components/UI/icons/DragAndDropIcon";
import BaseButton from "@/components/UI/buttons/BaseButton";
import { DropZone } from "dropzone-vue";

export default {
  name: "DragAndDrop",
  props: {
    modelValue: String,
    avatar: false,
    avatarOnAccount: false,
    haveOldImage: false,
  },
  components: { BaseButton, DragAndDropIcon, DropZone },
  methods: {
    onInputChange(event) {
      this.$store.commit("SET_LOADED_STATE", true);
      let formData = new FormData();
      formData.append("file", event.file);
      this.$http
        .post("uploads", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          this.$emit("update:modelValue", data.body);
          this.$store.commit("SET_LOADED_STATE", false);
        })
        .catch((err) => {
          if (
            err.response.data.status === 400 ||
            err.response.data.status === 413
          ) {
            this.$store.commit("SET_LOADED_STATE", false);
            this.toast.error(err.response.data.message);
          } else {
            this.$store.commit("SET_LOADED_STATE", false);
            this.toast.error(this.$t("NetworkError"));
          }
        });
    },
    remove() {
      if (!!this.$refs.dropzone.all[Object.keys(this.$refs.dropzone.all)[0]]) {
        this.$refs.dropzone.removeFile(
          this.$refs.dropzone.all[Object.keys(this.$refs.dropzone.all)[0]].id
        );
      }
    },
  },
};
</script>

<style></style>
