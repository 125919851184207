<template>
    <svg v-if="isActive" width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3" y="3" width="19" height="19" rx="2" fill="#0F243F"/>
        <rect x="7" y="7" width="6" height="1" fill="white"/>
        <rect x="7" y="10" width="11" height="1" fill="white"/>
        <rect x="7" y="13" width="11" height="1" fill="white"/>
        <rect x="7" y="16" width="11" height="1" fill="white"/>
    </svg>
    <svg v-else width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="3" y="3" width="19" height="19" rx="2" fill="white"/>
        <rect x="7" y="7" width="6" height="1" fill="#0F243F"/>
        <rect x="7" y="10" width="11" height="1" fill="#0F243F"/>
        <rect x="7" y="13" width="11" height="1" fill="#0F243F"/>
        <rect x="7" y="16" width="11" height="1" fill="#0F243F"/>
    </svg>
</template>

<script>
export default {
    props: {
        isActive: Boolean
    },
    name: "BlogIcon"
}
</script>

<style scoped>

</style>
