<template>
  <div class="cartBarCard">
    <div class="d-flex justify-content-between">
      <div>
        <div class="cartBarCard__title">
          {{ data.consultation.service.name }}
          <span class="cartBarCard__title-lang">{{ data.language.code }}</span>
        </div>
        <div class="cartBarCard__type">{{ data.consultation.name }}</div>
        <div class="cartBarCard__duration">
          {{ $t("duration") }}
          <duration-time :data="data.consultation.duration" />
        </div>
      </div>
      <div class="text-end">
        <div class="cartBarCard__day">
          <data-formatted date-format="EEEE" :data="data.time" />
          ,
        </div>
        <div class="cartBarCard__data">
          <data-day :data="data.time" />
        </div>
        <div class="cartBarCard__time">
          <data-formatted date-format="HH:mm" :data="data.time" />
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex align-items-center">
        <avatar
          user-role="experts"
          :image-name="data.consultation.expert.image"
          :user-code="data.consultation.expert.code"
          size="100"
          width="60px"
          height="60px"
        />
        <div class="ms-3">
          <div class="cartBarCard__name">
            <short-name
              :name="
                data.consultation.expert.first_name +
                ' ' +
                data.consultation.expert.last_name
              "
            />
          </div>
          <div class="cartBarCard__position">
            {{ data.consultation.expert.position }}
          </div>
        </div>
      </div>
      <div class="cartBarCard__coast">€{{ data.price }}</div>
    </div>
    <div @click="removeCartItem(data.id)" class="cartBarCard__delete">
      {{ $t("cartRemoveBtn") }}
    </div>
  </div>
</template>

<script>
import Avatar from "@/components/UI/images/Avatar";
import DataDay from "@/components/components-helpers/DataDay";
import DurationTime from "@/components/components-helpers/DurationTime";
import ShortName from "@/components/components-helpers/ShortName";
import DataFormatted from "@/components/components-helpers/DataFormatted";
import { useToast } from "vue-toastification";

export default {
  props: {
    data: Object,
    updatedItems: Function,
  },
  name: "CartBarCard",
  components: { DataFormatted, ShortName, DurationTime, DataDay, Avatar },
  setup() {
    const toast = useToast();

    return {
      toast,
    };
  },
  methods: {
    removeCartItem(data) {
      this.$http
        .delete(`/cart/item/${data}`)
        .then(() => {
          this.toast.success(this.$t("ItemWasSuccessfullyDeleted"));
          this.updatedItems();
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            this.toast.error(err.response.data.message);
          } else {
            this.toast.error(this.$t("NetworkError"));
          }
        });
    },
  },
};
</script>

<style></style>
