<template>
  <aside class="sidebar_grid">
    <div class="sidebar__logo">
      <aura-impact-logo />
    </div>
    <upcoming-appointment
      :class="{ 'animate__animated animate__fadeInLeft': upcomingAnimation }"
      v-if="
        $route.path !== '/' &&
        (loginUser.role_id === 4 || loginUser.role_id === 2)
      "
    />
    <div v-if="loginUser.role_id === 4" class="sidebar__button-container">
      <div class="sidebar__button-scale">
        <base-button :sidebar="true" link="/" margin-bottom="15px">
          <template #icon>
            <home-icon :is-active="checkButtonState('/')" />
          </template>
          {{ $t("sidebarHomeBtn") }}
        </base-button>
        <base-button
          v-if="showElement"
          :sidebar="true"
          link="/history"
          margin-bottom="15px"
        >
          <template #icon>
            <appointments-side-bar-icon
              :is-active="checkButtonState('/history')"
            />
          </template>
          {{ $t("sidebarAppointmentsBtn") }}
        </base-button>
        <base-button :sidebar="true" link="/services" margin-bottom="15px">
          <template #icon>
            <services-icon :is-active="checkButtonState('/services')" />
          </template>
          {{ $t("sidebarServicesBtn") }}
        </base-button>
        <base-button :sidebar="true" link="/experts" margin-bottom="15px">
          <template #icon>
            <experts-icon :is-active="checkButtonState('/experts')" />
          </template>
          {{ $t("sidebarExpertsBtn") }}
        </base-button>
        <base-button
          v-if="showElement"
          :sidebar="true"
          link="/transactions"
          margin-bottom="15px"
        >
          <template #icon>
            <transactions-icon :is-active="checkButtonState('/transactions')" />
          </template>
          {{ $t("sidebarTransactionsBtn") }}
        </base-button>
        <base-button :sidebar="true" link="/blog" margin-bottom="30px">
          <template #icon>
            <blog-icon :is-active="checkButtonState('/blog')" />
          </template>
          {{ $t("sidebarBlogNewsBtn") }}
        </base-button>
      </div>
    </div>
    <div v-if="loginUser.role_id === 3" class="sidebar__button-container">
      <div class="sidebar__button-scale">
        <base-button :sidebar="true" link="/" margin-bottom="15px">
          <template #icon>
            <home-icon :is-active="checkButtonState('/')" />
          </template>
          {{ $t("sidebarHomeBtn") }}
        </base-button>
        <base-button :sidebar="true" link="/employees" margin-bottom="15px">
          <template #icon>
            <employee-icon :is-active="checkButtonState('/employees')" />
          </template>
          {{ $t("sidebarEmployeesBtn") }}
        </base-button>
        <base-button :sidebar="true" link="/support" margin-bottom="15px">
          <template #icon>
            <support-sidebar-icon :is-active="checkButtonState('/support')" />
          </template>
          {{ $t("sidebarSupport") }}
        </base-button>
      </div>
    </div>
    <div v-if="loginUser.role_id === 2" class="sidebar__button-container">
      <div class="sidebar__button-scale">
        <base-button :sidebar="true" link="/" margin-bottom="15px">
          <template #icon>
            <home-icon :is-active="checkButtonState('/')" />
          </template>
          {{ $t("sidebarHomeBtn") }}
        </base-button>
        <base-button :sidebar="true" link="/history" margin-bottom="15px">
          <template #icon>
            <history-icon :is-active="checkButtonState('/history')" />
          </template>
          {{ $t("sidebarHistoryBtn") }}
        </base-button>
        <base-button :sidebar="true" link="/statistics" margin-bottom="15px">
          <template #icon>
            <statistics-icon :is-active="checkButtonState('/statistics')" />
          </template>
          {{ $t("sidebarStatisticsBtn") }}
        </base-button>
        <base-button :sidebar="true" link="/blog" margin-bottom="30px">
          <template #icon>
            <blog-icon :is-active="checkButtonState('/blog')" />
          </template>
          {{ $t("sidebarBlogNewsBtn") }}
        </base-button>
      </div>
    </div>
    <div v-if="loginUser.role_id === 1" class="sidebar__button-container">
      <div class="sidebar__button-scale">
        <base-button :sidebar="true" link="/" margin-bottom="15px">
          <template #icon>
            <home-icon :is-active="checkButtonState('/')" />
          </template>
          {{ $t("sidebarHomeBtn") }}
        </base-button>
        <base-button :sidebar="true" link="/employees" margin-bottom="15px">
          <template #icon>
            <employee-icon :is-active="checkButtonState('/employees')" />
          </template>
          {{ $t("sidebarEmployeesBtn") }}
        </base-button>
        <base-button :sidebar="true" link="/companies" margin-bottom="15px">
          <template #icon>
            <companies-icon :is-active="checkButtonState('/companies')" />
          </template>
          {{ $t("sidebarCompanyBtn") }}
        </base-button>
        <base-button :sidebar="true" link="/services" margin-bottom="15px">
          <template #icon>
            <services-icon :is-active="checkButtonState('/services')" />
          </template>
          {{ $t("sidebarServicesBtn") }}
        </base-button>
        <base-button :sidebar="true" link="/experts" margin-bottom="15px">
          <template #icon>
            <experts-icon :is-active="checkButtonState('/experts')" />
          </template>
          {{ $t("sidebarExpertsBtn") }}
        </base-button>
        <base-button :sidebar="true" link="/transactions" margin-bottom="15px">
          <template #icon>
            <transactions-icon :is-active="checkButtonState('/transactions')" />
          </template>
          {{ $t("sidebarTransactionsBtn") }}
        </base-button>
        <base-button :sidebar="true" link="/blog" margin-bottom="30px">
          <template #icon>
            <blog-icon :is-active="checkButtonState('/blog')" />
          </template>
          {{ $t("sidebarBlogNewsBtn") }}
        </base-button>
        <base-button :sidebar="true" link="/newsletters" margin-bottom="30px">
          <template #icon>
            <newsletter-icon :is-active="checkButtonState('/newsletters')" />
          </template>
          {{ $t("sidebarNewsletterBtn") }}
        </base-button>
      </div>
    </div>
    <div class="sidebar__footer">
      <div class="sidebar__link-content">
        <div
          v-if="loginUser.role_id === 2 || loginUser.role_id === 4"
          class="sidebar__mail-content"
        >
          {{ $t("sidebarContactSupportBtn") }}:
        </div>
        <a
          v-if="loginUser.role_id === 2 || loginUser.role_id === 4"
          :href="
            loginUser.role_id === 4
              ? 'mailto:support@aura-impact.com'
              : 'mailto:expert@aura-impact.com'
          "
        >
          {{
            loginUser.role_id === 4
              ? "support@aura-impact.com"
              : "expert@aura-impact.com"
          }}
        </a>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <router-link class="sidebar__user-information" to="/account">
          <avatar
            v-if="loginUser.role_id === 2 || loginUser.role_id === 4"
            class="me-2"
            :user-role="loginUser.role_id === 2 ? 'experts' : 'employees'"
            size="100"
            :user-code="loginUser.code"
            :image-name="loginUser.image"
            width="40px"
            height="40px"
          />
          {{
            loginUser.role_id === 1 ? $t("sidebarAdminName") : loginUser.name
          }}
          <arrow class="ms-2" />
        </router-link>
        <div @click="logout()" class="accountPage__logoutIcon">
          <logout-icon />
        </div>
      </div>
    </div>
  </aside>
</template>

<script>
import BaseButton from "@/components/UI/buttons/BaseButton";
import HomeIcon from "@/components/UI/icons/buttonIcons/HomeIcon.vue";
import ServicesIcon from "@/components/UI/icons/buttonIcons/ServicesIcon.vue";
import ExpertsIcon from "@/components/UI/icons/buttonIcons/ExpertsIcon.vue";
import Arrow from "@/components/UI/icons/arrows/Arrow";
import DataDay from "@/components/components-helpers/DataDay";
import ShortName from "@/components/components-helpers/ShortName";
import UpcomingAppointment from "@/components/elements/appointment/UpcomingAppointment";
import EmployeeIcon from "@/components/UI/icons/buttonIcons/EmployeeIcon.vue";
import CompaniesIcon from "@/components/UI/icons/buttonIcons/CompaniesIcon.vue";
import TransactionsIcon from "@/components/UI/icons/buttonIcons/TransactionsIcon.vue";
import AppointmentsSideBarIcon from "@/components/UI/icons/sidebarIcons/AppointmentsSideBarIcon";
import NewsletterIcon from "@/components/UI/icons/buttonIcons/NewsletterIcon.vue";
import AuraImpactLogo from "@/components/UI/icons/AuraImpactLogo";
import HistoryIcon from "@/components/UI/icons/buttonIcons/HistoryIcon.vue";
import StatisticsIcon from "@/components/UI/icons/buttonIcons/StatisticsIcon.vue";
import Avatar from "@/components/UI/images/Avatar";
import { mapGetters } from "vuex";
import SupportSidebarIcon from "@/components/UI/icons/sidebarIcons/SupportSidebarIcon";
import LogoutIcon from "@/components/UI/icons/addRemoveIcons/LogoutIcon";
import BlogIcon from "@/components/UI/icons/buttonIcons/BlogIcon.vue";

export default {
  name: "TheSidebar",
  components: {
    BlogIcon,
    LogoutIcon,
    SupportSidebarIcon,
    Avatar,
    StatisticsIcon,
    HistoryIcon,
    AuraImpactLogo,
    NewsletterIcon,
    AppointmentsSideBarIcon,
    TransactionsIcon,
    CompaniesIcon,
    EmployeeIcon,
    UpcomingAppointment,
    ShortName,
    DataDay,
    BaseButton,
    HomeIcon,
    ServicesIcon,
    ExpertsIcon,
    Arrow,
  },
  data: () => {
    return {
      showElement: process.env.VUE_APP_SHOW_SIDEBAR_ELEMENTS === "dev",
    };
  },
  mounted() {
    setTimeout(() => {
      if (this.$route.path !== "/") {
        this.$store.commit("SET_UPCOMING_ANIMATION", false);
      } else {
        this.$store.commit("SET_UPCOMING_ANIMATION", true);
      }
    }, 950);
  },
  methods: {
    logout() {
      this.$http.post("auth/logout").then(() => {
        localStorage.removeItem("cart");
        this.sockets.unsubscribe("receive-message");
        this.sockets.unsubscribe("receive-chats");
        this.$router.push("/login");
      });
    },
    checkButtonState(value) {
      return !(this.$route.path === value);
    },
  },
  computed: {
    ...mapGetters({
      loginUser: "CURRENT_USER_INFORMATION",
      upcomingAppointment: "UPCOMING_APPOINTMENT",
      upcomingAnimation: "UPCOMING_ANIMATION",
    }),
  },
};
</script>

<style></style>
