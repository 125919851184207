<template>
    <svg :class="{ 'arrowThink__white': nearest }" width="34" height="34" viewBox="0 0 34 34" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M1 33L33 1M33 1H7M33 1V27" stroke="black" stroke-width="1" stroke-linecap="round"/>
    </svg>

</template>

<script>
export default {
    props: {
        nearest: {
            type: Boolean,
            default: false
        }
    },
    name: "ArrowThink"
}
</script>

<style>

</style>
