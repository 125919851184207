<template>
    <svg v-if="!appointmentIcon" width="18" height="18" viewBox="0 0 21 18" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M0.858167 9.14183H20.1418M20.1418 9.14183L12.3078 1.30784M20.1418 9.14183L12.3078 16.9758"
              stroke-width="1.5" stroke="black" stroke-linecap="round"/>
    </svg>
    <svg v-if="appointmentIcon" width="20" height="20" viewBox="0 0 20 20" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <path
                d="M14.3565 11.7349L10.16 15.9314C10.1142 15.9772 10.0543 16 9.9943 16C9.93428 16 9.87435 15.9772 9.82858 15.9314L5.63206 11.7349C5.54061 11.6434 5.54061 11.495 5.63206 11.4034C5.72351 11.312 5.87196 11.312 5.96351 11.4034L9.75994 15.1999L9.76003 4.23433C9.76003 4.10494 9.86507 4 9.99436 4C10.1237 4 10.2287 4.10504 10.2287 4.23433V15.1996L14.0251 11.4032C14.1166 11.3117 14.265 11.3117 14.3566 11.4032C14.4481 11.4948 14.4481 11.6432 14.3567 11.7348L14.3565 11.7349Z"
                fill="black" stroke="black" stroke-width="0.6"/>
    </svg>
</template>

<script>
export default {
    props: {
        appointmentIcon: false
    },
    name: "HorizontalArrow"
}
</script>

<style>

</style>
