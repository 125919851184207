<template>
  {{ value }}
</template>

<script>
import { format } from "date-fns";

export default {
  props: {
    data: new Date(),
    transactionsDateRange: false,
  },
  name: "DataDay",
  computed: {
    value() {
      if (this.transactionsDateRange) {
        let now = format(new Date(), "01 MMM yyyy");
        let transactionsData = format(new Date(this.data), "dd MMM yyyy");
        return now === transactionsData
          ? this.$t("today")
          : format(new Date(this.data), "01-dd MMM, yyyy:");
      } else {
        let now = format(new Date(), "dd MMM yyyy");
        let messageData = format(new Date(this.data), "dd MMM yyyy");
        return now === messageData
          ? this.$t("today")
          : format(new Date(this.data), "dd MMM yyyy");
      }
    },
  },
};
</script>

<style></style>
