<template>
  <div class="cartBar" :class="{ cartBar__active: visibility }">
    <div class="cartBar__titleContainer">
      <h1 class="m-0">{{ $t("cartBarTitle") }}</h1>
      <div @click="onClose">
        <close-button-icon />
      </div>
    </div>
    <div class="cartBar__counter">
      {{
        cartItems && cartItems.length > 0
          ? cartItems.length + $t("cartBarCounter")
          : $t("cartBarNoAppointment")
      }}
    </div>
    <div class="cartBar__container">
      <cart-bar-card
        v-for="item in cartItems"
        :key="item.id"
        :data="item"
        :updated-items="getCartItems"
      />
    </div>
    <router-link class="notificationBar__link" to="/cart">
      <div>
        {{ $t("cartLinkBtn") }}
        <arrow width="11.31px" height="11.31px" />
      </div>
    </router-link>
  </div>
</template>

<script>
import Arrow from "@/components/UI/icons/arrows/Arrow";
import CartBarCard from "@/components/elements/card/CartBarCard";
import HorizontalArrow from "@/components/UI/icons/arrows/HorizontalArrow";
import CloseButtonIcon from "@/components/UI/icons/CloseButtonIcon";
import { mapGetters } from "vuex";

export default {
  components: { CloseButtonIcon, HorizontalArrow, CartBarCard, Arrow },
  props: {
    onClose: { type: Function },
    visibility: {
      type: Boolean,
      default: false,
    },
  },
  name: "TheCartBar",
  mounted() {
    this.getCartItems();
  },
  methods: {
    getCartItems() {
      if (this.loginUser.role_id === 4) {
        this.$http.get("/cart/items").then(({ data }) => {
          this.$store.commit("SET_CART_ITEMS", data.body);
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      cartItems: "CART_ITEMS",
      loginUser: "CURRENT_USER_INFORMATION",
    }),
  },
};
</script>

<style></style>
