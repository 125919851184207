<template>
  <div class="popup">
    <div class="position-relative">
      <div @click="close()" class="popup__closeIcon">
        <close-popup-icon />
      </div>
      <div class="passwordResetPopup-title">
        {{ $t("AddNewTag") }}
      </div>
      <div class="row mb-20">
        <div class="col-6">
          <base-input type="text" v-model="tag.english">
            {{ $t("EnglishName") }}
          </base-input>
        </div>
        <div class="col-6">
          <base-input type="text" v-model="tag.french">
            {{ $t("FrenchName") }}
          </base-input>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <div class="passwordResetPopup-cancel" @click="close()">
          {{ $t("cancel") }}
        </div>
        <base-button class="passwordResetPopup-submit" @click="createTag(tag)">
          {{ $t("submit") }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/UI/buttons/BaseButton.vue";
import BaseInput from "@/components/UI/inputs/BaseInput.vue";
import { useToast } from "vue-toastification";
import ClosePopupIcon from "@/components/UI/icons/addRemoveIcons/ClosePopupIcon.vue";

export default {
  name: "AddNewTagPopup",
  components: { ClosePopupIcon, BaseInput, BaseButton },
  props: {
    onClose: Function,
  },
  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },
  data() {
    return {
      tag: {
        english: null,
        french: null,
      },
    };
  },
  methods: {
    createTag(tag) {
      if (tag.english && tag.french) {
        this.$http
          .post("/blog/tags", {
            translations: [
              {
                language_id: 1,
                name: tag.english,
              },
              {
                language_id: 2,
                name: tag.french,
              },
            ],
          })
          .then(() => {
            this.toast.success(this.$t("successfulSaveData"));
            this.$store.commit("SET_BLOG_TAGS", []);
            this.close();
          })
          .catch((err) => {
            if (err.response.data.status === 400) {
              this.toast.error(err.response.data.message);
            } else {
              this.toast.error(this.$t("NetworkError"));
            }
          });
      }
    },
    close() {
      this.$store.commit("SET_ADD_NEW_TAG", false);
      this.onClose();
    },
  },
};
</script>

<style scoped></style>
