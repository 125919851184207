<template>
  <div class="passwordResetPopup-container">
    <div class="position-relative">
      <div @click="onClose" class="popup__closeIcon">
        <close-popup-icon />
      </div>
      <div class="passwordResetPopup-title">
        {{ $t("headerEditResetPasswordBtn") }}
      </div>
      <div v-if="this.$route.path === '/account'">
        <div class="passwordResetPopup-subtitle">
          {{ $t("wantToChangePassword") }}
        </div>
        <div class="passwordResetPopup-description">
          {{ $t("AdminPasswordChangeText") }}
        </div>
      </div>
      <div v-else class="passwordResetPopup-description">
        {{ $t("AfterChangingPassword") }},
        <span
          v-if="
            this.$route.path === '/expert/edit/' + this.$route.params.expert
          "
          >{{ $t("expert") }}</span
        >
        <span
          v-if="this.$route.path === '/company/' + this.$route.params.company"
          >{{ $t("company") }}</span
        >
        <span
          v-if="this.$route.path === '/employee/' + this.$route.params.employee"
          >{{ $t("employee") }}</span
        >
        {{ $t("willReceiveMail") }}
      </div>
      <div>
        <div class="position-relative mb-30">
          <base-input
            class="w-100 passwordResetPopup__input"
            v-model="password"
            type="text"
          >
            {{ $t("enterNewPassword") }}
          </base-input>
          <base-button
            class="buttons__passwordGenerate"
            @click="passwordGenerate()"
          >
            {{ $t("generate") }}
          </base-button>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <div class="passwordResetPopup-cancel" @click="onClose">
          {{ $t("cancel") }}
        </div>
        <base-button class="passwordResetPopup-submit" @click="resetPassword()"
          >{{ $t("Change") }}
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/UI/buttons/BaseButton";
import BaseInput from "@/components/UI/inputs/BaseInput";
import ClosePopupIcon from "@/components/UI/icons/addRemoveIcons/ClosePopupIcon";
import { useToast } from "vue-toastification";

export default {
  props: {
    onClose: Function,
  },
  components: { ClosePopupIcon, BaseInput, BaseButton },
  name: "PasswordResetPopup",
  setup() {
    const toast = useToast();

    return {
      toast,
    };
  },
  data() {
    return {
      password: "",
      userInformation: {},
    };
  },
  mounted() {
    this.userInformation = this.$store.getters.EDIT_USER_INFORMATION;
  },
  methods: {
    passwordGenerate() {
      this.password = Math.floor(Math.random() * 1000000000000000000).toString(
        36
      );
    },
    resetPassword() {
      this.$http
        .patch("/users/" + this.userInformation.user_id + "/reset-password", {
          password: this.password,
          email: this.userInformation.email,
          role_id: this.userInformation.role_id,
        })
        .then(() => {
          this.toast.success(this.$t("passwordSuccessesChange"));
          this.onClose();
        });
    },
  },
};
</script>

<style></style>
