<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2110_1766)">
            <circle cx="10" cy="10" r="9.5" stroke="#0F243F"/>
            <path d="M9.862 6.264C9.57267 6.264 9.33 6.166 9.134 5.97C8.938 5.774 8.84 5.53133 8.84 5.242C8.84 4.95267 8.938 4.71 9.134 4.514C9.33 4.318 9.57267 4.22 9.862 4.22C10.142 4.22 10.38 4.318 10.576 4.514C10.772 4.71 10.87 4.95267 10.87 5.242C10.87 5.53133 10.772 5.774 10.576 5.97C10.38 6.166 10.142 6.264 9.862 6.264ZM10.646 7.286V15H9.05V7.286H10.646Z"
                  fill="#0F243F"/>
        </g>
        <defs>
            <clipPath id="clip0_2110_1766">
                <rect width="20" height="20" fill="white"/>
            </clipPath>
        </defs>
    </svg>
</template>

<script>
export default {
    name: "InformationIcon"
}
</script>

<style>

</style>
