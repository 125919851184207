<template>
  <div class="popup">
    <div class="position-relative">
      <div @click="close()" class="popup__closeIcon">
        <close-popup-icon />
      </div>
      <div class="popup__title">
        {{ $t("ApplyingCredits") }}
      </div>
      <div class="popup__subtitle">
        {{ $t("InformationAboutCreditsApplying") }}
      </div>
      <div class="popup__priseText">
        {{ $t("PriceForAppointmentsBeforeApplyingCredits") }}
        <span>€{{ totalCost }}</span>
      </div>
      <div class="popup__inputContainer">
        <p class="popup__availableCredit">
          {{ $t("AvailableCredits") }} <span>€{{ credits }}</span>
        </p>
        <p>{{ $t("EnterCreditsNumberToApply") }}</p>
        <div>
          <base-input
            v-model="creditsNumber"
            class="popupAssign__input"
            mask="€ ####.##"
          />
        </div>
      </div>
      <div class="popup__footer">
        {{ $t("TotalToCharge") }}
        <span> €{{ totalCharge }} </span>
      </div>
      <base-button
        @click="applyCredits()"
        class="popup__button--fullWidth"
        :calendar="true"
      >
        {{ $t("ApplyCredits") }}
      </base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/UI/buttons/BaseButton.vue";
import BaseInput from "@/components/UI/inputs/BaseInput.vue";
import ClosePopupIcon from "@/components/UI/icons/addRemoveIcons/ClosePopupIcon.vue";
import { mapGetters } from "vuex";
import { clearString } from "@/helpers/clearStringToNumber";
import { useToast } from "vue-toastification";

export default {
  components: { ClosePopupIcon, BaseInput, BaseButton },
  setup() {
    const toast = useToast();

    return {
      toast,
    };
  },
  props: {
    onClose: Function,
  },
  name: "ApplyingCredits",
  data() {
    return {
      creditsNumber: 0,
    };
  },
  mounted() {
    this.creditsNumber = this.$store.getters.CREDIT_USED;
  },
  methods: {
    close() {
      this.onClose();
      this.$store.commit("SET_APPLYING_CREDITS", false);
    },
    applyCredits() {
      if (clearString(this.creditsNumber) > this.credits) {
        this.creditsNumber = `€ ${this.credits}`;
      }

      if (clearString(this.creditsNumber) > this.totalCost) {
        this.creditsNumber = `€ ${this.totalCost}`;
      }

      this.$http
        .patch("/cart/balance", {
          amount: clearString(this.creditsNumber),
        })
        .then(() => {
          this.updateCart();
          this.$store.commit("SET_APPLYING_CREDITS", false);
          this.onClose();
        })
        .catch((err) => {
          if (err.response.data.status === 400) {
            this.toast.error(err.response.data.message);
          } else {
            this.toast.error(this.$t("NetworkError"));
          }
        });
    },
    updateCart() {
      this.$http.get("/cart/items").then(({ data }) => {
        this.$store.commit("SET_CART_ITEMS", data.body);
      });
    },
  },
  computed: {
    ...mapGetters({
      totalCost: "TOTAL_COST",
      credits: "CREDITS",
      loginUser: "CURRENT_USER_INFORMATION",
      cart: "CART_ITEMS",
      cartItems: "CART_ITEMS",
    }),
    totalCharge() {
      if (
        clearString(this.totalCost) > clearString(this.creditsNumber).toFixed(2)
      ) {
        return Number(
          clearString(this.totalCost) - clearString(this.creditsNumber)
        ).toFixed(2);
      } else {
        return 0;
      }
    },
  },
};
</script>

<style scoped></style>
