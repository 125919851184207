<template>
    <svg width="25" height="26" viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9.54656 13C9.54656 13.157 9.60901 13.3077 9.72003 13.4187C9.83105 13.5299 9.98172 13.5922 10.1387 13.5922H21.7356L19.1937 16.1342V16.134C19.0808 16.2446 19.0168 16.3958 19.016 16.5539C19.0152 16.7119 19.0777 16.8637 19.1895 16.9755C19.3013 17.0873 19.453 17.1498 19.6112 17.149C19.7692 17.1482 19.9202 17.0842 20.0311 16.9712L23.5837 13.4185C23.6553 13.3432 23.7052 13.2498 23.7283 13.1485C23.7344 13.1251 23.739 13.1013 23.7421 13.0773C23.759 12.9756 23.7487 12.8711 23.7122 12.7748L23.712 12.7732L23.7074 12.7677V12.7679C23.6785 12.6983 23.6365 12.635 23.5837 12.5813L20.0311 9.02864C19.7991 8.80145 19.4276 8.80323 19.198 9.03281C18.9684 9.26238 18.9665 9.63412 19.1937 9.86585L21.7356 12.4078H10.1387C9.98173 12.4078 9.83106 12.4701 9.72004 12.5813C9.60902 12.6923 9.54657 12.843 9.54657 13L9.54656 13ZM1.25719 21.2894V4.71063C1.25799 3.92555 1.57023 3.17299 2.12531 2.61812C2.68024 2.06299 3.43279 1.75076 4.21767 1.75H13.6916C14.4765 1.75079 15.229 2.06304 15.7841 2.61812C16.3392 3.17304 16.6513 3.9256 16.6521 4.71063V9.44744C16.6521 9.77436 16.387 10.0396 16.0601 10.0396C15.733 10.0396 15.4679 9.77436 15.4679 9.44744V4.71063C15.4675 4.23959 15.2802 3.78816 14.9471 3.45512C14.614 3.12209 14.1626 2.93471 13.6916 2.9343H4.21767C3.74683 2.9347 3.2952 3.12205 2.96237 3.45512C2.62931 3.78819 2.44196 4.2396 2.44135 4.71063V21.2894C2.44194 21.7604 2.62929 22.2118 2.96237 22.5449C3.29523 22.8779 3.74685 23.0653 4.21767 23.0657H13.6916C14.1626 23.0653 14.6141 22.878 14.9471 22.5449C15.2801 22.2118 15.4675 21.7604 15.4679 21.2894V16.5526C15.4679 16.2256 15.733 15.9604 16.0601 15.9604C16.387 15.9604 16.6521 16.2256 16.6521 16.5526V21.2894C16.6513 22.0745 16.3392 22.827 15.7841 23.3819C15.229 23.937 14.4764 24.2492 13.6916 24.25H4.21767C3.43279 24.2492 2.68024 23.937 2.12531 23.3819C1.57018 22.827 1.25795 22.0744 1.25719 21.2894Z"
              fill="#DC2525"/>
    </svg>

</template>

<script>
export default {
    name: "LogoutIcon"
}
</script>

<style>

</style>
