<template>
    <svg v-if="isActive" width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
                d="M11.7 8.49988C11.7 7.43907 11.2785 6.42169 10.5282 5.67166C9.77818 4.92143 8.76086 4.5 7.70003 4.5C6.63902 4.5 5.62169 4.92141 4.8716 5.67166C4.12137 6.42174 3.69995 7.43907 3.69995 8.49988C3.69995 9.56091 4.12135 10.5782 4.8716 11.3283C5.62169 12.0785 6.63902 12.5 7.70003 12.5C8.76086 12.5 9.77823 12.0785 10.5282 11.3283C11.2785 10.5782 11.7 9.56091 11.7 8.49988ZM4.83106 8.49988C4.83106 7.73019 5.13759 6.99208 5.68273 6.44864C6.22804 5.90525 6.96714 5.60122 7.73683 5.60353C8.50672 5.60603 9.24371 5.91487 9.78557 6.46175C10.3273 7.00864 10.6289 7.74867 10.624 8.51856C10.6192 9.28825 10.3081 10.0245 9.75941 10.5644C9.2108 11.1043 8.46977 11.4038 7.70008 11.3964C6.93172 11.3964 6.19513 11.0913 5.65194 10.548C5.10874 10.0048 4.80352 9.26825 4.80352 8.49988H4.83106Z"
                fill="#0F243F"/>
        <path
                d="M4.83106 8.49988C4.83106 7.73019 5.13759 6.99208 5.68273 6.44864C6.22804 5.90525 6.96714 5.60122 7.73683 5.60353C8.50672 5.60603 9.24371 5.91487 9.78557 6.46175C10.3273 7.00864 10.6289 7.74867 10.624 8.51856C10.6192 9.28825 10.3081 10.0245 9.75941 10.5644C9.2108 11.1043 8.46977 11.4038 7.70008 11.3964C6.93172 11.3964 6.19513 11.0913 5.65194 10.548C5.10874 10.0048 4.80352 9.26825 4.80352 8.49988H4.83106Z"
                fill="#0F243F"/>
        <path
                d="M21 8C21 7.07176 20.6312 6.18153 19.9748 5.52506C19.3185 4.86871 18.4283 4.5 17.4999 4.5C16.5717 4.5 15.6815 4.86874 15.0252 5.52506C14.3688 6.18153 14 7.07176 14 8C14 8.92825 14.3688 9.8186 15.0252 10.4749C15.6815 11.1313 16.5717 11.5 17.4999 11.5C18.4283 11.5 19.3185 11.1313 19.9748 10.4749C20.6312 9.8186 21 8.92825 21 8ZM14.9897 8C14.9897 7.3265 15.2579 6.68063 15.735 6.20511C16.2121 5.72945 16.8588 5.46342 17.5323 5.46562C18.206 5.4678 18.8508 5.73805 19.3249 6.21657C19.7989 6.69511 20.0629 7.34267 20.0585 8.01634C20.0543 8.68984 19.782 9.33403 19.302 9.80649C18.822 10.279 18.1736 10.5409 17.4999 10.5346C16.8278 10.5346 16.1831 10.2675 15.708 9.79218C15.2327 9.31687 14.9656 8.67217 14.9656 8H14.9897Z"
                fill="#0F243F"/>
        <path
                d="M14.9897 8C14.9897 7.3265 15.2579 6.68063 15.735 6.20511C16.2121 5.72945 16.8588 5.46342 17.5323 5.46562C18.206 5.4678 18.8508 5.73805 19.3249 6.21657C19.7989 6.69511 20.0629 7.34267 20.0585 8.01634C20.0543 8.68984 19.782 9.33403 19.302 9.80649C18.822 10.279 18.1736 10.5409 17.4999 10.5346C16.8278 10.5346 16.1831 10.2675 15.708 9.79218C15.2327 9.31687 14.9656 8.67217 14.9656 8H14.9897Z"
                fill="#0F243F"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M2 18.0701V18.7587C2 19.1848 2.17183 19.5934 2.47775 19.8947C2.7837 20.196 3.19855 20.3652 3.63132 20.3652H11.3686C11.7973 20.3652 12.2088 20.1992 12.514 19.9029C12.8193 19.6066 12.9939 19.2038 12.9999 18.7817L13 17.8407C12.9371 16.543 12.3293 15.3292 11.3222 14.4899L10.5762 13.962C9.86465 13.5684 9.06174 13.3628 8.2457 13.3653H6.77743C5.51042 13.3653 4.29522 13.861 3.39918 14.7433C2.50331 15.6256 2 16.8224 2 18.0701Z"
              fill="#0F243F"/>
        <path
                d="M16.4564 12.2989H18.2758C19.5346 12.3065 20.739 12.8119 21.6265 13.7047C22.5138 14.5975 23.0118 15.8051 23.0118 17.0639V17.9303C23.0118 18.16 22.9205 18.3803 22.758 18.5429C22.5956 18.7053 22.3752 18.7965 22.1454 18.7965H14.1C14.1 17.1414 13.7479 15.4212 12.5 14.3652C13.3622 12.9422 14.7927 12.2914 16.4564 12.2989Z"
                fill="#0F243F"/>
    </svg>
    <svg v-else width="25" height="22" viewBox="0 0 25 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
                d="M11.7 8.49988C11.7 7.43907 11.2785 6.42169 10.5282 5.67166C9.77818 4.92143 8.76086 4.5 7.70003 4.5C6.63902 4.5 5.62169 4.92141 4.8716 5.67166C4.12137 6.42174 3.69995 7.43907 3.69995 8.49988C3.69995 9.56091 4.12135 10.5782 4.8716 11.3283C5.62169 12.0785 6.63902 12.5 7.70003 12.5C8.76086 12.5 9.77823 12.0785 10.5282 11.3283C11.2785 10.5782 11.7 9.56091 11.7 8.49988ZM4.83106 8.49988C4.83106 7.73019 5.13759 6.99208 5.68273 6.44864C6.22804 5.90525 6.96714 5.60122 7.73683 5.60353C8.50672 5.60603 9.24371 5.91487 9.78557 6.46175C10.3273 7.00864 10.6289 7.74867 10.624 8.51856C10.6192 9.28825 10.3081 10.0245 9.75941 10.5644C9.2108 11.1043 8.46977 11.4038 7.70008 11.3964C6.93172 11.3964 6.19513 11.0913 5.65194 10.548C5.10874 10.0048 4.80352 9.26825 4.80352 8.49988H4.83106Z"
                fill="white"/>
        <path
                d="M4.83106 8.49988C4.83106 7.73019 5.13759 6.99208 5.68273 6.44864C6.22804 5.90525 6.96714 5.60122 7.73683 5.60353C8.50672 5.60603 9.24371 5.91487 9.78557 6.46175C10.3273 7.00864 10.6289 7.74867 10.624 8.51856C10.6192 9.28825 10.3081 10.0245 9.75941 10.5644C9.2108 11.1043 8.46977 11.4038 7.70008 11.3964C6.93172 11.3964 6.19513 11.0913 5.65194 10.548C5.10874 10.0048 4.80352 9.26825 4.80352 8.49988H4.83106Z"
                fill="white"/>
        <path
                d="M21 8C21 7.07176 20.6312 6.18153 19.9748 5.52506C19.3185 4.86871 18.4283 4.5 17.4999 4.5C16.5717 4.5 15.6815 4.86874 15.0252 5.52506C14.3688 6.18153 14 7.07176 14 8C14 8.92825 14.3688 9.8186 15.0252 10.4749C15.6815 11.1313 16.5717 11.5 17.4999 11.5C18.4283 11.5 19.3185 11.1313 19.9748 10.4749C20.6312 9.8186 21 8.92825 21 8ZM14.9897 8C14.9897 7.3265 15.2579 6.68063 15.735 6.20511C16.2121 5.72945 16.8588 5.46342 17.5323 5.46562C18.206 5.4678 18.8508 5.73805 19.3249 6.21657C19.7989 6.69511 20.0629 7.34267 20.0585 8.01634C20.0543 8.68984 19.782 9.33403 19.302 9.80649C18.822 10.279 18.1736 10.5409 17.4999 10.5346C16.8278 10.5346 16.1831 10.2675 15.708 9.79218C15.2327 9.31687 14.9656 8.67217 14.9656 8H14.9897Z"
                fill="white"/>
        <path
                d="M14.9897 8C14.9897 7.3265 15.2579 6.68063 15.735 6.20511C16.2121 5.72945 16.8588 5.46342 17.5323 5.46562C18.206 5.4678 18.8508 5.73805 19.3249 6.21657C19.7989 6.69511 20.0629 7.34267 20.0585 8.01634C20.0543 8.68984 19.782 9.33403 19.302 9.80649C18.822 10.279 18.1736 10.5409 17.4999 10.5346C16.8278 10.5346 16.1831 10.2675 15.708 9.79218C15.2327 9.31687 14.9656 8.67217 14.9656 8H14.9897Z"
                fill="white"/>
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M2 18.0701V18.7587C2 19.1848 2.17183 19.5934 2.47775 19.8947C2.7837 20.196 3.19855 20.3652 3.63132 20.3652H11.3686C11.7973 20.3652 12.2088 20.1992 12.514 19.9029C12.8193 19.6066 12.9939 19.2038 12.9999 18.7817L13 17.8407C12.9371 16.543 12.3293 15.3292 11.3222 14.4899L10.5762 13.962C9.86465 13.5684 9.06174 13.3628 8.2457 13.3653H6.77743C5.51042 13.3653 4.29522 13.861 3.39918 14.7433C2.50331 15.6256 2 16.8224 2 18.0701Z"
              fill="white"/>
        <path
                d="M16.4564 12.2989H18.2758C19.5346 12.3065 20.739 12.8119 21.6265 13.7047C22.5138 14.5975 23.0118 15.8051 23.0118 17.0639V17.9303C23.0118 18.16 22.9205 18.3803 22.758 18.5429C22.5956 18.7053 22.3752 18.7965 22.1454 18.7965H14.1C14.1 17.1414 13.7479 15.4212 12.5 14.3652C13.3622 12.9422 14.7927 12.2914 16.4564 12.2989Z"
                fill="white"/>
    </svg>

</template>

<script>
export default {
    props: {
        isActive: Boolean
    },
    name: "EmployeeIcon"
}
</script>

<style>

</style>
