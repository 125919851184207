<template>
  <div
    v-if="upcomingAppointment"
    class="sidebar__upcomingAppointment-container"
  >
    <h5>{{ $t("sidebarUpcoming") }}</h5>
    <router-link
      v-if="upcomingAppointment.code"
      :to="/appointment/ + upcomingAppointment.code"
      class="sidebar__upcomingAppointment"
    >
      <div class="d-flex align-items-center">
        <avatar
          v-if="upcomingAppointment.expert_code"
          user-role="experts"
          :user-code="upcomingAppointment.expert_code"
          :image-name="upcomingAppointment.expert_image"
          size="100"
          width="60px"
          height="60px"
        />
        <avatar
          v-if="upcomingAppointment.employee_code"
          width="60px"
          height="60px"
        />
        <div class="sidebar__upcomingAppointment-mentorInfo">
          <div class="sidebar__upcomingAppointment-name">
            <short-name
              v-if="upcomingAppointment.expert_first_name"
              :name="
                upcomingAppointment.expert_first_name +
                ' ' +
                upcomingAppointment.expert_last_name
              "
            />
            <short-name
              v-if="upcomingAppointment.employee_first_name"
              :name="
                upcomingAppointment.employee_first_name +
                ' ' +
                upcomingAppointment.employee_last_name
              "
            />
          </div>
          <div class="sidebar__upcomingAppointment-position">
            {{ upcomingAppointment.consultation_name }}
          </div>
        </div>
      </div>
      <div class="sidebar__upcomingAppointment-dataTime">
        <div class="sidebar__upcomingAppointment-data">
          <data-day :data="upcomingAppointment.time" />
        </div>
        <div class="sidebar__upcomingAppointment-time">
          <data-formatted
            date-format="HH:mm"
            :data="upcomingAppointment.time"
          />
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import Avatar from "@/components/UI/images/Avatar";
import ShortName from "@/components/components-helpers/ShortName";
import DataDay from "@/components/components-helpers/DataDay";
import DataFormatted from "@/components/components-helpers/DataFormatted";
import { mapGetters } from "vuex";

export default {
  components: { DataDay, DataFormatted, ShortName, Avatar },
  name: "UpcomingAppointment",
  computed: {
    ...mapGetters({
      upcomingAppointment: "UPCOMING_APPOINTMENT",
    }),
  },
};
</script>

<style></style>
