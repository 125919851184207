<template>
  {{ value }}
</template>

<script>
import { format } from "date-fns";

export default {
  props: {
    data: String,
  },
  name: "FullData",
  data() {
    return {
      value: String,
    };
  },
  mounted() {
    this.dataFormat(this.data);
  },
  methods: {
    dataFormat(data) {
      let now = format(new Date(), "dd MMM yyyy");
      let messageData = format(new Date(data), "dd MMM yyyy");
      this.value =
        now === messageData
          ? this.$t("today") + format(new Date(data), "HH:mm")
          : format(new Date(data), "dd MMM HH:mm");
    },
  },
};
</script>

<style></style>
