<template>
  <div class="popup">
    <div class="position-relative">
      <div @click="onClose" class="popup__closeIcon">
        <close-popup-icon />
      </div>
      <div class="passwordResetPopup-title">
        {{ !is_blocked ? "Block" : "Unblock" }}
        <span
          v-if="
            this.$route.path === '/expert/edit/' + this.$route.params.expert
          "
          >expert</span
        >
        <span
          v-if="this.$route.path === '/company/' + this.$route.params.company"
          >company</span
        >
        <span
          v-if="this.$route.path === '/employee/' + this.$route.params.employee"
          >employee</span
        >?
      </div>
      <div class="passwordResetPopup-subtitle">
        {{ !is_blocked ? $t("wantToBlock") : $t("sureWantToUnblock") }}
        {{ `${userInformation.first_name} ${userInformation.last_name}` }}?
      </div>
      <div class="popup-description">
        <span
          v-if="
            this.$route.path === '/expert/edit/' + this.$route.params.expert
          "
        >
          {{
            !is_blocked ? $t("blockExpertPopupText") : $t("unblockExpertPopup")
          }}
        </span>
        <span
          v-if="this.$route.path === '/company/' + this.$route.params.company"
        >
          {{
            !is_blocked
              ? $t("blockCompanyPopupText")
              : $t("unblockCompanyPopupText")
          }}
        </span>
        <span
          v-if="this.$route.path === '/employee/' + this.$route.params.employee"
        >
          {{
            !is_blocked
              ? $t("blockEmployeePopupText")
              : $t("unblockEmployeePopupText")
          }}
        </span>
      </div>
      <div class="d-flex align-items-center justify-content-end">
        <div class="passwordResetPopup-cancel" @click="onClose">
          {{ $t("cancel") }}
        </div>
        <base-button class="passwordResetPopup-submit" @click="block()">
          <span
            v-if="
              this.$route.path === '/expert/edit/' + this.$route.params.expert
            "
          >
            {{
              !is_blocked ? $t("headerBlockExpert") : $t("headerUnblockExpert")
            }}
          </span>
          <span
            v-if="this.$route.path === '/company/' + this.$route.params.company"
          >
            {{
              !is_blocked
                ? $t("headerBlockCompany")
                : $t("headerUnblockCompany")
            }}
          </span>
          <span
            v-if="
              this.$route.path === '/employee/' + this.$route.params.employee
            "
          >
            {{
              !is_blocked
                ? $t("headerBlockEmployee")
                : $t("headerUnblockEmployee")
            }}
          </span>
        </base-button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/UI/buttons/BaseButton";
import { useToast } from "vue-toastification";
import ClosePopupIcon from "@/components/UI/icons/addRemoveIcons/ClosePopupIcon";

export default {
  components: { ClosePopupIcon, BaseButton },
  props: {
    onClose: Function,
  },
  name: "BlockPopup",
  setup() {
    const toast = useToast();
    return {
      toast,
    };
  },
  data() {
    return {
      password: "",
      userInformation: {},
      is_blocked: Boolean,
    };
  },
  mounted() {
    this.userInformation = this.$store.getters.EDIT_USER_INFORMATION;
    this.is_blocked = this.userInformation.is_blocked;
  },
  methods: {
    block() {
      this.$http
        .patch(
          `/profiles/${this.userInformation.profile_id}/block/` +
            !this.is_blocked
        )
        .then(() => {
          !this.is_blocked
            ? this.toast.success(this.$t("userBlock"))
            : this.toast.success(this.$t("userUnblock"));
          this.userInformation.is_blocked = !this.is_blocked;
          this.$store.commit("SET_EDIT_USER_INFORMATION", this.userInformation);
          this.onClose();
        });
    },
  },
};
</script>

<style></style>
