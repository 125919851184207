<template>
  <div
    class="row employeeCompanyCart"
    :class="{
      'employeeCompanyCart--assign': assign,
      'employeeCompanyCart--assign-active': isSelect,
    }"
  >
    <div class="col-6 employeeCart__info">
      <div>{{ employee.email }}</div>
    </div>
    <div class="col-3 employeeCart__info">
      {{ employee.balance ? `€${employee.balance}` : "" }}
    </div>
    <div class="col-3">
      <base-button
        v-if="!assign"
        class="popup__remove-logo-btn w-max-content ms-auto me-0"
        @click="deleteEmployee(employee)"
      >
        {{ $t("Delete") }}
      </base-button>
      <base-button
        v-if="assign"
        class="employeeCompanyCart__assignSelectButton"
        :class="{ 'employeeCompanyCart__assignSelectButton--active': isSelect }"
        :calendar="true"
        @click="selectEmployee(employee.id)"
      >
        {{ isSelect ? $t("Deselect") : $t("Select") }}
      </base-button>
    </div>
  </div>
</template>

<script>
import ArrowThink from "@/components/UI/icons/arrows/ArrowThink";
import Avatar from "@/components/UI/images/Avatar";
import BaseButton from "@/components/UI/buttons/BaseButton";

export default {
  props: {
    employee: {},
    assign: false,
    selectEmployee: Function,
    isSelect: false,
  },
  name: "EmployeesCompanyTable",
  components: { BaseButton, Avatar, ArrowThink },
  methods: {
    deleteEmployee(value) {
      let data = {
        show: true,
        user_id: value.id,
        email: value.email,
        role_id: 4,
      };
      this.$store.commit("SET_DELETE_EMPLOYEE_POPUP", data);
    },
  },
};
</script>

<style></style>
