<template>
  {{ value }}
</template>

<script>
import { format } from "date-fns";

export default {
  props: {
    data: new Date(),
    dateFormat: String,
  },
  name: "DataFormatted",
  computed: {
    value() {
      if (this.data) {
        return format(new Date(this.data), this.dateFormat);
      } else {
        return "";
      }
    },
  },
};
</script>

<style></style>
