<template>
    <svg v-if="isActive" width="25" height="25" viewBox="0 0 25 25" fill="none"
         xmlns="http://www.w3.org/2000/svg">
        <circle cx="12.5" cy="12.5" r="11.5" fill="#0F243F"/>
        <path
                d="M12.5867 4.60256C9.03158 4.60256 6.00716 6.88517 4.95165 10.0583L4.20773 8.73566C4.11362 8.56205 3.9229 8.46375 3.72682 8.48757C3.56512 8.50586 3.42355 8.60483 3.35075 8.75042C3.27795 8.89602 3.28382 9.06863 3.36618 9.20904L4.68883 11.5686C4.8143 11.7951 5.09677 11.8815 5.32759 11.7641L7.67963 10.5617C7.79873 10.5072 7.89031 10.4064 7.93343 10.2827C7.97654 10.159 7.96731 10.0231 7.90793 9.90633C7.84855 9.78958 7.74405 9.70219 7.61874 9.66412C7.49344 9.62621 7.35807 9.64097 7.24384 9.70504L5.84604 10.419C6.75572 7.60863 9.42361 5.56439 12.587 5.56439C16.5029 5.56439 19.6587 8.68436 19.6587 12.5381C19.6587 16.3919 16.5031 19.5119 12.587 19.5119C9.2369 19.5119 6.44079 17.2156 5.70348 14.1463V14.1465C5.68603 14.0114 5.61239 13.8902 5.50051 13.8125C5.38879 13.7348 5.2494 13.7083 5.11672 13.7394C4.9842 13.7702 4.87114 13.8559 4.80522 13.975C4.73947 14.0943 4.72722 14.2357 4.77151 14.3644C5.61239 17.8646 8.79678 20.4737 12.5869 20.4737C17.0159 20.4737 20.62 16.9186 20.62 12.538C20.62 8.15752 17.0154 4.60242 12.5869 4.60242L12.5867 4.60256ZM12.6318 8.20211C12.3841 8.22928 12.1982 8.44131 12.2036 8.69057V12.5382V12.5381C12.2034 12.6843 12.2698 12.8226 12.3839 12.9138L14.7886 14.8376C14.9961 15.0037 15.2989 14.97 15.465 14.7625C15.631 14.555 15.5973 14.2522 15.3898 14.0862L13.1654 12.3051V8.6904V8.69057C13.1684 8.55202 13.1115 8.419 13.0092 8.3254C12.9071 8.2318 12.7695 8.18684 12.6318 8.20211H12.6318Z"
                fill="white"/>
    </svg>
    <svg v-else width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12.5" cy="12.5" r="11.5" fill="#F1F7FB"/>
        <path
                d="M12.5867 4.60268C9.03158 4.60268 6.00716 6.88529 4.95165 10.0584L4.20773 8.73578C4.11362 8.56217 3.9229 8.46387 3.72682 8.48769C3.56512 8.50598 3.42355 8.60495 3.35075 8.75055C3.27795 8.89614 3.28382 9.06875 3.36618 9.20916L4.68883 11.5688C4.8143 11.7952 5.09677 11.8816 5.32759 11.7642L7.67963 10.5618C7.79873 10.5073 7.89031 10.4065 7.93343 10.2829C7.97654 10.1591 7.96731 10.0232 7.90793 9.90646C7.84855 9.7897 7.74405 9.70231 7.61874 9.66424C7.49344 9.62633 7.35807 9.64109 7.24384 9.70517L5.84604 10.4191C6.75572 7.60875 9.42361 5.56451 12.587 5.56451C16.5029 5.56451 19.6587 8.68449 19.6587 12.5382C19.6587 16.392 16.5031 19.512 12.587 19.512C9.2369 19.512 6.44079 17.2158 5.70348 14.1464V14.1466C5.68603 14.0116 5.61239 13.8903 5.50051 13.8126C5.38879 13.7349 5.2494 13.7084 5.11672 13.7395C4.9842 13.7703 4.87114 13.8561 4.80522 13.9752C4.73947 14.0944 4.72722 14.2358 4.77151 14.3645C5.61239 17.8647 8.79678 20.4738 12.5869 20.4738C17.0159 20.4738 20.62 16.9187 20.62 12.5382C20.62 8.15764 17.0154 4.60254 12.5869 4.60254L12.5867 4.60268ZM12.6318 8.20223C12.3841 8.2294 12.1982 8.44143 12.2036 8.69069V12.5384V12.5382C12.2034 12.6845 12.2698 12.8227 12.3839 12.9139L14.7886 14.8378C14.9961 15.0038 15.2989 14.9701 15.465 14.7626C15.631 14.5551 15.5973 14.2523 15.3898 14.0863L13.1654 12.3052V8.69053V8.69069C13.1684 8.55214 13.1115 8.41912 13.0092 8.32552C12.9071 8.23192 12.7695 8.18696 12.6318 8.20223H12.6318Z"
                fill="#0F243F"/>
    </svg>
</template>

<script>
export default {
    props: {
        isActive: Boolean
    },
    name: "HistoryIcon"
}
</script>

<style>

</style>
