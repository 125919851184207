<template>
  <div class="container_grid">
    <TheHeader />
    <TheSidebar />
    <div class="content_grid animate__animated animate__fadeInRight">
      <slot />
    </div>
  </div>
</template>

<script>
import TheSidebar from "@/components/blocks/TheSidebar";
import TheHeader from "@/components/blocks/TheHeader";
import { mapGetters } from "vuex";
import { format, setDefaultOptions } from "date-fns";
import { fr } from "date-fns/locale";
import { useToast } from "vue-toastification";

export default {
  name: "MainLayout",
  setup() {
    const toast = useToast();

    return {
      toast,
    };
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  },
  components: { TheSidebar, TheHeader },
  created() {
    this.setLocale(this.loginUser.language_code);
    if (this.loginUser.role_id === 4 && this.$route.path === "/calendar") {
      this.sockets.subscribe("reschedule", (data) => {
        let scheduleSelectInformation = this.$store.getters.CALENDAR_SELECT;
        if (data.experts.includes(scheduleSelectInformation.expert.user_id)) {
          this.$store.commit("SET_LOADED_STATE", true);
          let today =
            format(new Date(this.$store.getters.CALENDAR_DAY), "yyyy-MM-dd") +
            "T00:00:00.000Z";
          this.$http
            .get("/calendar/schedule", {
              params: {
                consultationId: scheduleSelectInformation.type.id,
                date: today,
                daysCount: 4,
              },
            })
            .then(({ data }) => {
              this.$store.commit("SET_DAY_OPTIONS", data.body);
              setTimeout(() => {
                this.$store.commit("SET_LOADED_STATE", false);
              }, 1000);
            });
        }
      });
    } else {
      this.sockets.unsubscribe("reschedule");
    }
    this.sockets.subscribe("receive-message", (data) => {
      let recipientId = this.loginUser.role_id === 4 ? 2 : 1;
      let chats = this.$store.getters.CHATS;
      let messages = this.$store.getters.CHAT_MESSAGES;
      chats.forEach((chat) => {
        if (chat.id === data.message.chat_id) chat.messages = [data.message];
      });
      if (messages.id === data.message.chat_id)
        messages.messages.push(data.message);
      if (recipientId !== data.message.recipient_id) {
        // import("@/assets/sound/notification.mp3").then((module) => {
        //   const audio = new Audio(module.default);
        //   audio.play();
        // })
        if (this.$store.getters.SHOW_CHAT) {
          this.$store.commit("SET_NOTIFICATIONS", false);
        } else {
          this.$store.commit("SET_NOTIFICATIONS", true);
        }
      }
      this.$store.commit("SET_CHAT_MESSAGES", messages);

      this.$store.commit("SET_CHATS", chats);
    });
    this.sockets.subscribe("receive-chats", (data) => {
      if (this.loginUser.id === data.userId) {
        let haveNotification = false;
        let recipientId = this.loginUser.role_id === 4 ? 2 : 1;
        data.chats.forEach((chat) => {
          if (
            chat.messages[0] &&
            !chat.messages[0].is_read &&
            recipientId !== chat.messages[0].recipient_id
          ) {
            haveNotification = true;
          }
        });
        this.$store.commit("SET_CHATS", data.chats);
        this.$store.commit("SET_NOTIFICATIONS", haveNotification);
      }
    });
    this.sockets.subscribe("cart-expired", (data) => {
      if (
        this.loginUser.role_id === 4 &&
        this.loginUser.id === data.employeeId
      ) {
        this.$http.get("/cart/items").then(({ data }) => {
          this.toast.info(this.$t("ExpiredCardItems"), {
            position: "top-right",
            timeout: 100000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 2,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
          this.$store.commit("SET_CART_ITEMS", data.body);
        });
      }
    });
  },
  beforeCreate() {
    document.title = this.$route.meta.title[this.$i18n.locale];
  },
  methods: {
    setLocale(locale) {
      import(`../languages/${locale}.json`).then((msgs) => {
        document.documentElement.setAttribute("lang", locale);
        this.$i18n.setLocaleMessage(locale, msgs);
        this.$i18n.locale = locale;
        setDefaultOptions({ locale: locale === "fr" ? fr : null });
      });
    },
  },
  computed: {
    ...mapGetters({
      notifications: "CHATS",
      loginUser: "CURRENT_USER_INFORMATION",
    }),
    gridTemplateRows() {
      return this.loginUser.role_id === 4 ? "120px 1fr" : "80px 1fr";
    },
  },
};
</script>

<style scoped>
.container_grid {
  position: relative;
  display: grid;
  grid-template-columns: 25% 75%;
  grid-template-rows: v-bind("gridTemplateRows");
  gap: 0 0;
  grid-template-areas:
    "Sidebar Header"
    "Sidebar Content";
}
</style>
