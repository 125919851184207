<template>
  <header class="header_grid">
    <div v-if="loginUser.role_id === 4" class="header__credits">
      {{ $t("AvailableCredits") + ` €${creditsValue}` }}
    </div>
    <div class="header__container">
      <div class="d-flex">
        <base-button
          class="mr-30"
          v-if="loginUser.role_id === 4 || loginUser.role_id === 2"
          link="/calendar"
          :calendar="true"
        >
          <template #icon>
            <calendar-icon />
          </template>
          {{ $t("headerCalendarBtn") }}
        </base-button>
        <base-button
          :header="true"
          class="mr-30"
          v-if="loginUser.role_id === 4 || loginUser.role_id === 2"
          @click="sidebarOpen('notification')"
        >
          <template #icon>
            <notification-icon />
          </template>
          {{ $t("headerMessagesBtn") }}
        </base-button>
        <base-button
          :header="true"
          v-if="loginUser.role_id === 4"
          margin-left="30px"
          @click="sidebarOpen('cart')"
        >
          <template #icon>
            <cart-icon />
          </template>
          {{ $t("headerCartBtn") }}
        </base-button>
        <base-button
          v-if="loginUser.role_id === 1 && this.$route.path === '/companies'"
          class="header__btn"
          @click="createUser('company')"
        >
          {{ $t("headerAddCompanyBtn") }}
        </base-button>
        <base-button
          v-if="loginUser.role_id === 1 && this.$route.path === '/services'"
          class="header__btn"
          link="/service/new"
        >
          {{ $t("createNewService") }}
        </base-button>
        <base-button
          v-if="loginUser.role_id === 1 && this.$route.path === '/blog'"
          class="header__btn"
          link="/blog/new"
        >
          {{ $t("AddNewBlogPost") }}
        </base-button>
        <base-button
          class="header__btn"
          v-if="
            loginUser.role_id === 1 &&
            this.$route.path === '/service/' + this.$route.params.service
          "
          :link="'/service/edit/' + this.$route.params.service"
        >
          {{ $t("headerEditServiceBtn") }}
        </base-button>
        <base-button
          class="header__btn"
          v-if="loginUser.role_id === 1 && this.$route.path === '/experts'"
          @click="createUser('expert')"
        >
          {{ $t("headerAddExpertsBtn") }}
        </base-button>
        <base-button
          class="header__btn mr-30"
          v-if="loginUser.role_id === 3 && this.$route.path === '/employees'"
          @click="creditPopup('withdrawal')"
        >
          {{ $t("WithdrawCredits") }}
        </base-button>
        <base-button
          class="header__btn mr-30"
          v-if="loginUser.role_id === 3 && this.$route.path === '/employees'"
          @click="creditPopup('assign')"
        >
          {{ $t("AssignCredits") }}
        </base-button>
        <base-button
          class="header__btn mr-30"
          v-if="
            (loginUser.role_id === 1 || loginUser.role_id === 3) &&
            this.$route.path === '/employees'
          "
          @click="createUser('employee')"
        >
          {{ $t("headerAddEmployeeBtn") }}
        </base-button>
        <base-button
          class="header__btn"
          v-if="loginUser.role_id === 3 && this.$route.path === '/employees'"
          @click="popupShow('list')"
        >
          {{ $t("AddEmployees") }}
        </base-button>
        <base-button
          class="header__btn"
          v-if="loginUser.role_id === 3 && this.$route.path === '/'"
          @click="downloadStatistic()"
        >
          {{ $t("DownloadStatisticsCSV") }}
        </base-button>
        <router-link
          :to="userInformation.previewPath ? userInformation.previewPath : ''"
          class="header__companyButtons"
          v-if="
            loginUser.role_id === 1 &&
            (this.$route.path ===
              '/service/edit/' + this.$route.params.service ||
              this.$route.path ===
                '/expert/edit/' + this.$route.params.expert ||
              this.$route.path === '/blog/edit/' + this.$route.params.blog)
          "
        >
          {{ $t("Preview") }}
        </router-link>
        <div
          class="header__companyButtons"
          @click="changePassword()"
          v-if="loginUser.role_id === 3 && this.$route.path === '/account'"
        >
          {{ $t("headerEditResetPasswordBtn") }}
        </div>
        <div
          class="header__companyButtons"
          @click="popupShow('password')"
          v-if="
            loginUser.role_id === 1 &&
            (this.$route.path === '/expert/edit/' + this.$route.params.expert ||
              this.$route.path === '/employee/' + this.$route.params.employee ||
              this.$route.path === '/company/' + this.$route.params.company ||
              this.$route.path === '/account')
          "
        >
          {{ $t("headerEditResetPasswordBtn") }}
        </div>
        <div
          class="header__companyButtons"
          @click="popupShow('block')"
          v-if="
            loginUser.role_id === 1 &&
            this.$route.path === '/company/' + this.$route.params.company
          "
        >
          {{
            userInformation.is_blocked
              ? $t("headerUnblockCompany")
              : $t("headerBlockCompany")
          }}
        </div>
        <div
          class="header__companyButtons red"
          @click="popupShow('block')"
          v-if="
            loginUser.role_id === 1 &&
            this.$route.path === '/expert/edit/' + this.$route.params.expert
          "
        >
          {{
            userInformation.is_blocked
              ? $t("headerUnblockExpert")
              : $t("headerBlockExpert")
          }}
        </div>
        <div
          class="header__companyButtons red"
          @click="popupShow('block')"
          v-if="
            loginUser.role_id === 1 &&
            this.$route.path === '/employee/' + this.$route.params.employee
          "
        >
          {{
            userInformation.is_blocked
              ? $t("headerUnblockEmployee")
              : $t("headerBlockEmployee")
          }}
        </div>
        <div
          class="header__companyButtons red"
          @click="popupShow('delete')"
          v-if="
            loginUser.role_id === 1 &&
            this.$route.path === '/company/' + this.$route.params.company
          "
        >
          {{ $t("headerDeleteCompany") }}
        </div>
        <div
          class="header__companyButtons red"
          @click="popupShow('delete')"
          v-if="
            loginUser.role_id === 1 &&
            this.$route.path === '/service/edit/' + this.$route.params.service
          "
        >
          {{ $t("headerDeleteService") }}
        </div>
        <div
          class="header__companyButtons red"
          @click="popupShow('delete')"
          v-if="
            loginUser.role_id === 1 &&
            this.$route.path === '/expert/edit/' + this.$route.params.expert
          "
        >
          {{ $t("headerDeleteExpert") }}
        </div>
        <div
          class="header__companyButtons red"
          @click="popupShow('delete')"
          v-if="
            loginUser.role_id === 1 &&
            this.$route.path === '/employee/' + this.$route.params.employee
          "
        >
          {{ $t("deleteEmployee") }}
        </div>
        <div
          class="header__companyButtons red"
          @click="popupShow('delete')"
          v-if="
            loginUser.role_id === 1 &&
            this.$route.path === '/blog/edit/' + this.$route.params.blog
          "
        >
          {{ $t("DeletePost") }}
        </div>
      </div>
    </div>
  </header>
  <div
    @click="closePopup()"
    class="notificationBar__bg"
    :class="{ notificationBar__bg__active: popupBg }"
  ></div>
  <create-new-user-popup v-if="popup.newUser" :on-close="closePopup" />
  <add-new-tag-popup v-if="popup.addTag" :on-close="closePopup" />
  <import-employees-list v-if="popup.list" :on-close="closePopup" />
  <cancel-appointment v-if="popup.cancel" :on-close="closePopup" />
  <save-changes-popup v-if="popup.changes" :on-close="closePopup" />
  <password-reset-popup v-if="popup.password" :on-close="closePopup" />
  <change-employee-password
    v-if="popup.changePassword"
    :on-close="closePopup"
  />
  <change-employee-password-no-save
    v-if="popup.leaveNoSave"
    :on-close="closePopup"
  />
  <block-popup v-if="popup.block" :on-close="closePopup" />
  <delete-popup v-if="popup.delete" :on-close="closePopup" />
  <change-company-image-popup
    v-if="popup.companyImage"
    :on-close="closePopup"
  />
  <reschedule-appointment-popup
    v-if="popup.reschedule"
    :on-close="closePopup"
  />
  <credits-popup
    v-if="popup.creditsPopup"
    :bonus-state="creditsState"
    :on-close="closePopup"
  />
  <applying-credits v-if="popup.applyingCredits" :on-close="closePopup" />
  <the-notification-bar
    v-if="loginUser.role_id === 2 || loginUser.role_id === 4"
    :on-close="closePopup"
    :visibility="aside.notification"
  />
  <the-cart-bar :on-close="closePopup" :visibility="aside.cart" />
</template>

<script>
import { mapGetters } from "vuex";
import SaveChangesPopup from "@/components/elements/popups/saveChangesPopup";
import PasswordResetPopup from "@/components/elements/popups/PasswordResetPopup";
import BlockPopup from "@/components/elements/popups/BlockPopup";
import DeletePopup from "@/components/elements/popups/DeletePopup";
import ChangeCompanyImagePopup from "@/components/elements/popups/ChangeCompanyImagePopup";
import TheNotificationBar from "@/components/blocks/TheNotificationBar";
import TheCartBar from "@/components/blocks/TheCartBar";
import BaseButton from "@/components/UI/buttons/BaseButton";
import CartIcon from "@/components/UI/icons/buttonIcons/CartIcon.vue";
import NotificationIcon from "@/components/UI/icons/buttonIcons/NotificationIcon.vue";
import CalendarIcon from "@/components/UI/icons/CalendarIcon";
import RescheduleAppointmentPopup from "@/components/elements/popups/RescheduleAppointmentPopup";
import ChangeEmployeePassword from "@/components/elements/popups/ChangeEmployeePassword";
import ChangeEmployeePasswordNoSave from "@/components/elements/popups/ChangeEmployeePasswordNoSave";
import CreateNewUserPopup from "@/components/elements/popups/CreateNewUserPopup";
import CancelAppointment from "@/components/elements/popups/CancelAppointment";
import ImportEmployeesList from "@/components/elements/popups/ImportEmployeesList";
import AddNewTagPopup from "@/components/elements/popups/AddNewTagPopup.vue";
import CreditsPopup from "@/components/elements/popups/CreditsPopup.vue";
import ApplyingCredits from "@/components/elements/popups/ApplyingCredits.vue";

export default {
  name: "TheHeader",
  components: {
    ApplyingCredits,
    CreditsPopup,
    AddNewTagPopup,
    ImportEmployeesList,
    CancelAppointment,
    CreateNewUserPopup,
    ChangeEmployeePasswordNoSave,
    ChangeEmployeePassword,
    RescheduleAppointmentPopup,
    CalendarIcon,
    NotificationIcon,
    CartIcon,
    BaseButton,
    TheCartBar,
    TheNotificationBar,
    ChangeCompanyImagePopup,
    DeletePopup,
    BlockPopup,
    PasswordResetPopup,
    SaveChangesPopup,
  },
  data() {
    return {
      popupBg: false,
      creditsState: true,
      popup: {
        password: false,
        block: false,
        delete: false,
        changes: false,
        companyImage: false,
        reschedule: false,
        changePassword: false,
        leaveNoSave: false,
        newUser: false,
        list: false,
        cancel: false,
        addTag: false,
        creditsPopup: false,
        applyingCredits: false,
      },
      aside: {
        notification: false,
        cart: false,
      },
    };
  },
  mounted() {
    this.closePopup();
    this.$store.commit("SET_SHOW_CALENDAR", false);
    if (this.creditsValue === null && this.loginUser.role_id === 4)
      this.getCreditBalance();
  },
  methods: {
    creditPopup(action) {
      this.creditsState = action === "assign";
      this.popupShow("creditsPopup");
    },
    getCreditBalance() {
      this.$http.get("/employees/balance").then(({ data }) => {
        this.$store.commit("SET_CREDITS", data.body.credits);
      });
    },
    downloadStatistic() {
      this.$http
        .get("/statistics/company/exportStats")
        .then(({ data }) => {
          window.open(data.body);
        })
        .catch((err) => {
          if (
            err.response.data.status === 400 ||
            err.response.data.status === 413
          ) {
            this.$store.commit("SET_LOADED_STATE", false);
            this.toast.error(err.response.data.message);
          } else {
            this.$store.commit("SET_LOADED_STATE", false);
            this.toast.error(this.$t("NetworkError"));
          }
        });
    },
    createUser(value) {
      this.$store.commit("SET_USER_ROLE_CREATE", value);
      this.popupShow("newUser");
    },
    popupShow(value) {
      this.popup[value] = true;
      this.popupBg = true;
      document.body.style.overflow = "hidden";
    },
    sidebarOpen(value) {
      this.aside[value] = true;
      this.popupBg = true;
      document.body.style.overflow = "hidden";
    },
    closePopup() {
      for (let i in this.popup) {
        this.popup[i] = false;
      }
      for (let i in this.aside) {
        this.aside[i] = false;
      }
      this.popupBg = false;
      this.$store.commit("SET_LOGO_POPUP", false);
      this.$store.commit("SET_APPLYING_CREDITS", false);
      if (!this.$router.currentRoute.value.fullPath.includes("/new")) {
        this.$store.commit("SET_NEW_USER_CREDENTIALS", null);
      }
      document.body.style.overflow = "auto";
    },
    changePassword() {
      this.$store.commit("SET_CHANGE_EMPLOYEE_PASSWORD_POPUP", true);
    },
  },
  computed: {
    ...mapGetters({
      creditsValue: "CREDITS",
      haveChanges: "STATE_POPUP",
      reschedules: "RESCHEDULE_APPOINTMENT_POPUP",
      companyLogoPopup: "COMPANY_LOGO_POPUP",
      userInformation: "EDIT_USER_INFORMATION",
      loginUser: "CURRENT_USER_INFORMATION",
      changePasswordPopup: "CHANGE_EMPLOYEE_PASSWORD_POPU",
      leaveNoSave: "CHANGE_EMPLOYEE_PASSWORD_LEAVE_POPUP",
      credentialsPopup: "CREDENTIALS_POPUP",
      cancelAppointmentPopup: "CANCEL_APPOINTMENT",
      addNewTag: "ADD_NEW_TAG",
      deleteEmployee: "DELETE_EMPLOYEE_POPUP",
      applyingCredits: "APPLYING_CREDITS",
    }),
  },
  watch: {
    reschedules: {
      deep: true,
      handler(newCount) {
        this.popup.reschedule = newCount.show;
        this.popupBg = newCount.show;
      },
    },
    changePasswordPopup: {
      deep: true,
      handler(newCount) {
        this.popup.changePassword = newCount;
        this.popupBg = newCount;
      },
    },
    leaveNoSave: {
      deep: true,
      handler(newCount) {
        this.popup.leaveNoSave = newCount.show;
        this.popupBg = newCount.show;
      },
    },
    haveChanges: {
      deep: true,
      handler(newCount) {
        this.popup.changes = newCount.show;
        this.popupBg = newCount.show;
      },
    },
    deleteEmployee: {
      deep: true,
      handler() {
        this.popupShow("delete");
      },
    },
    companyLogoPopup: {
      deep: true,
      handler() {
        this.popupShow("companyImage");
      },
    },
    credentialsPopup: {
      deep: true,
      handler() {
        this.popupShow("newUser");
      },
    },
    cancelAppointmentPopup: {
      deep: true,
      handler() {
        this.popupShow("cancel");
      },
    },
    addNewTag: {
      deep: true,
      handler(value) {
        if (value) {
          this.popupShow("addTag");
        }
      },
    },
    applyingCredits: {
      deep: true,
      handler(value) {
        if (value) {
          this.popupShow("applyingCredits");
        }
      },
    },
  },
};
</script>

<style></style>
