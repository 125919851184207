<template>
  <div class="notificationBar" :class="{ notificationBar__active: visibility }">
    <div class="row notificationBar__titleContainer">
      <h1 class="w-auto m-0">{{ $t("notificationsBarTitle") }}</h1>
      <div class="w-auto">
        <close-button-icon @click="onClose" />
      </div>
    </div>
    <div class="notificationBar__container">
      <div v-for="notification in notifications" :key="notification.id">
        <notifications-cards
          v-if="
            notification.messages[0] &&
            !notification.messages[0].is_read &&
            recipientId !== notification.messages[0].recipient_id
          "
          :notification="notification"
        />
      </div>
    </div>
    <router-link class="notificationBar__link" to="/chats">
      <div>
        {{ $t("notificationsBarBtn") }}
        <arrow width="11.31px" height="11.31px" />
      </div>
    </router-link>
  </div>
</template>

<script>
import CloseButtonIcon from "@/components/UI/icons/CloseButtonIcon";
import NotificationsCards from "@/components/elements/card/NotificationsCards";
import Arrow from "@/components/UI/icons/arrows/Arrow";
import { mapGetters } from "vuex";

export default {
  props: {
    onClose: { type: Function },
    visibility: {
      type: Boolean,
      default: false,
    },
  },
  name: "TheNotificationBar",
  components: { CloseButtonIcon, NotificationsCards, Arrow },
  beforeMount() {
    this.getNotifications();
  },
  methods: {
    getNotifications() {
      if (this.notifications.length === 0) {
        this.$http.get("/chats").then(({ data }) => {
          if (data.body.length > 0) {
            data.body.forEach((chat) => {
              this.$socket.emit("join-chat", { chatId: chat.id });
              if (
                chat.messages[0] &&
                !chat.messages[0].is_read &&
                chat.messages[0].recipient_id !== this.recipientId
              )
                this.$store.commit("SET_NOTIFICATIONS", true);
            });
            this.$store.commit("SET_CHATS", data.body);
          }
        });
      }
    },
  },
  computed: {
    ...mapGetters({
      notifications: "CHATS",
      loginUser: "CURRENT_USER_INFORMATION",
    }),
    recipientId() {
      return this.loginUser.role_id === 4 ? 2 : 1;
    },
  },
};
</script>

<style>
body {
  overflow-x: hidden;
}
</style>
